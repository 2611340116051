import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { API_GRADE, API_USER } from "../../../redux/apiRoutes";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Form,
} from "reactstrap";

import { useSelector } from "react-redux";
import axios from "axios";
import { useMemo } from "react";
import FilterComponent from "../../common/filter";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import Compressor from 'compressorjs';

import AWS from 'aws-sdk';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_REGION;
const AWS_URL_BUCKET = process.env.REACT_APP_AWS_URL_BUCKET;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET},
  region: REGION,
})

function Student(props) {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [modal, setModal] = useState(false);
  const [modalStudent, setModalStudent] = useState(false);
  const [dataUsers, setDataUsers] = useState([]);

  const toggle = () => {setModal(!modal); reset(); setEditingUser(false); setAuthPick([]); setgradeSelected([]);};
  const toggleStudent = () => {setModalStudent(!modalStudent)};

  const [getUsers, setGetUsers] = useState(false);
  const getUsersList = async () => {
    setGetUsers(true);

    await axios.get(`${API_USER}?status=ACTIVE,DISABLED&role=STUDENT`).then(response => {
      if(response.data.docs.length>0){
        setDataUsers(response.data.docs);
      }
      setGetUsers(false);
    }).catch(e => {
      setGetUsers(false);
      toast.error("No se pudo obtener el listado de Alumnos: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [gettingUserProfile, setGettingProfile] = useState(false);
  const [tutorsData, setTutorsData] = useState([]);
  const handleViewProfile = async (user) => {
    setGettingProfile(true);
    setuserData(user);
    toggleStudent();

    await axios.get(`${API_USER}?status=ACTIVE,DISABLED&role=TUTOR&childUuid=${user.uuid}`).then(response => {
      console.log(response.data.docs)
      if(response.data.docs.length>0){
        setTutorsData(response.data.docs);
      }
      setGettingProfile(false);
    }).catch(e => {
      setGettingProfile(false);
      toast.error("No se pudo obtener la informaciuón de padres del Alumno(a): " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });

  }

  const [isEditingUser, setEditingUser] = useState(false);
  const [authPick, setAuthPick] = useState([]);
  const [userData, setuserData] = useState(false);
  const handleViewUser = (user) => {
    toggle();
    setEditingUser(true);
    setuserData(user);
    setgradeSelected(user.grade);

    setTimeout(()=>{
      setValue('nia', user.nia);
      setValue('names', user.names);
      setValue('second_names', user.second_names);
      setValue('blood_type', user.blood_type);
      setValue('medicals', user.medicals);
      
      if(user.people_auth_pick.length > 0){
        setAuthPick(user.people_auth_pick);
      }
    },500)
  }

  const [addingPeople, setAddingPeople] = useState(false);
  const handleAddNewAuthPeople = () => {
    setAddingPeople(true);
  }
  
  const handleCancelAddNewAuthPeople = () => {
    setAddingPeople(false);
  }

  const [namePicker, setNamePicker] = useState('');
  const [relationPicker, setRelationPicker] = useState('');
  const [filePicker, setFilePicker] = useState('');
  const [addingPicker, setAddingPicker] = useState(false);
  const createPicker = async () => {
    setAddingPicker(true);

    if(namePicker===''){
      setAddingPicker(false);
      toast.error("Ingresa el Nombre de la persona Autorizada: ", {position: 'bottom-right', style:{color:'white'}});
      return;
    }

    if(filePicker===''){
      setAddingPicker(false);
      toast.error("Carga un archivo de identificación para la persona autorizada: ", {position: 'bottom-right', style:{color:'white'}});
      return;
    }
    const file = filePicker;

    if(authPick.length>=4){
      setAddingPicker(false);
      toast.error("Límite de personas autorizadas de 4 alcanzado: ", {position: 'bottom-right', style:{color:'white'}});
      return;
    }

    new Compressor(file, {
      quality: 0.8,
      success: async (compressedResult) => {   
        const compressed = compressedResult;
        
        const fileName = userData.names.split(' ')[0].toUpperCase() + '_' + namePicker.split(' ')[0].toUpperCase() + '_' + '_AUTORIZADO' + '_' + file.name;
        const params = {
            ACL: 'public-read',
            Body: compressed,
            Key: `picker-documents/${fileName}`,
            Bucket: S3_BUCKET,
        };
        myBucket.putObject(params)
          .on('httpUploadProgress', (evt) => {
            console.log(Math.round((evt.loaded / evt.total) * 100));
          })
          .send(async (err) => {
              if (err) {
                toast.error("Error al cargar el documento: "+err, {position: 'bottom-right', style:{color:'white'}});
              }else{
                await axios.post(`${API_USER}/add-picker/${userData.uuid}`,{document:`${AWS_URL_BUCKET}/picker-documents/${fileName}`, name: namePicker, relation: relationPicker}).then((response) => {
                  if(response.data){
                    setAuthPick(response.data);
                    setAddingPicker(false);
                    setNamePicker('');
                    setFilePicker('');
                    setAddingPeople(false);
                    toast.success("Persona Autorizada, guardada con éxito! ", {position: 'bottom-right', style:{color:'white'}});
                  }
                }).catch(e =>{
                  toast.error("Error al guardar la información: "+e.message, {position: 'bottom-right', style:{color:'white'}});
                })
              }
              setAddingPicker(false);
          })
      },
    });
  }
  
  const [removingPicker, seRemovingPicker] = useState(false);
  const handleRemoveAuthPicker = (picker) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se quitará ${picker.name} de la lista de personas Autorizadas para recoger a ${userData.names}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        seRemovingPicker(true);

        const deleteParams = {
          Bucket: S3_BUCKET,
          Key: `picker-documents/${picker.document.split('/picker-documents/')[1]}`
        }
        myBucket.deleteObject(deleteParams).send();

        await axios.post(`${API_USER}/remove-picker/${userData.uuid}`,picker).then(response => {
          setAuthPick(response.data);
          seRemovingPicker(false);
          SweetAlert.fire("¡Listo!", `persona quitada de la lista`, "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar la persona: " + error
            );
          }, 200);
        });
      }
    });
  }

  const handleStatusUser = (user,status) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se ${status === 'DISABLED' ? 'desactivará' : 'activará'} el usuario: ${user.names}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await axios.patch(`${API_USER}/${user.uuid}`,{status:status}).then(response => {
          getUsersList();
          SweetAlert.fire("¡Listo!", `usuario ${status === 'DISABLED' ? 'desactivado' : 'activado'}`, "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al desactivar el usuario: " + error
            );
          }, 200);
        });
      }
    });
  }

  const handleDeleteUser = (user) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminara el usuario: ${user.names}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await axios.delete(`${API_USER}/${user.uuid}`).then(response => {
          getUsersList();
          SweetAlert.fire("¡Listo!", "usuario eliminado", "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar el usuario: " + error
            );
          }, 200);
        });
      }
    });
  }

  const columns = [
    {
      name: "NIA",
      selector: (row) => <b>{row.nia}</b>,
      sortable: true,
      center: true,
      width: '120px'
    },
    {
      name: "Nombres",
      selector: (row) => <b>{row.names}</b>,
      sortable: true,
      center: true,
      width: '180px'
    },
    {
      name: "Apellidos",
      selector: (row) => <b>{row.second_names}</b>,
      sortable: true,
      center: true,
    },
    {
      name: "Grado",
      selector: (row) => <div>
                          {
                            row.grade.length > 0 ?
                              row.grade.map((grade)=>{
                                return(<div><span className="badge badge-dark">{grade.name}</span> <br/></div>)
                              })
                            : null
                          }
                        </div>,
      width: '150px',
      center: true,
    },
    {
      name: "Estado",
      selector: (row) => row.status === 'DISABLED' ? <span className="badge badge-warning">DESACTIVADO</span> : <span className="badge badge-success">ACTIVO</span>,
      center: true,
      sortable: true,
    },
    {
      name: "Creado",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
      center: true,
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleViewProfile(row)}}><i className="fa fa-eye"></i></Button>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleViewUser(row)}}><i className="fa fa-pencil"></i></Button>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleStatusUser(row, row.status === 'DISABLED' ? 'ACTIVE' : 'DISABLED')}}><i className="fa fa-power-off"></i></Button>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleDeleteUser(row)}}><i className="fa fa-trash"></i></Button>
                        </div>,
      center: true,
      width: '260px'
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const filteredItems = dataUsers.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [creatingUser, setCreatingUser] = useState(false);
  const onSubmitCreateUser = async (user) => {
    setCreatingUser(true);

    if (user !== "") {
      user.uuid = uuidv4();
      user.role = 'STUDENT';
      user.status = 'ACTIVE';
      if(user.email === ''){delete(user.email)}
      if(gradeSelected.length>0){user.grade = gradeSelected}

      await axios.post(`${API_USER}`,user).then(response => {
        if(response.data){
          toggle();
          getUsersList();
          reset();
          toast.success("Alumno creado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setCreatingUser(false);
      }).catch(e => {
        setCreatingUser(false);
        toast.error("No se pudo crear el Alumno(a): " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [patchingUser, setPatchingUser] = useState(false);
  const onSubmitPatchUser = async (user) => {
    setPatchingUser(true);

    if (user !== "") {
      if(gradeSelected.length>0){user.grade = gradeSelected}else{user.grade = []}

      await axios.patch(`${API_USER}/${userData.uuid}`,user).then(response => {
        if(response.data){
          toggle();
          getUsersList();
          reset();
          toast.success("Alumno actualizado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setPatchingUser(false);
      }).catch(e => {
        setPatchingUser(false);
        toast.error("No se pudo actualizar el Alumno(a): " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [gradeSelected,setgradeSelected] = useState([]);
  const [dataGrades,setdataGrades] = useState([]);
  const getGradesList = async () => {
    await axios.get(`${API_GRADE}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        const grades = []
        response.data.docs.map(grade=>{
          const addGrade = {
            uuid: grade.uuid,
            name: grade.name + ' - ' + grade.level
          }
          grades.push(addGrade);
        });
        setdataGrades(grades);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Grados escolares: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  useEffect(() => {
    getUsersList();
    getGradesList();
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "RECEPCIONISTA"
      && user.role !== "COORDINADOR_GENERAL"
      && user.role !== "COORDINADOR_PREESCOLAR"
      && user.role !== "COORDINADOR_PRIMARIA"
      && user.role !== "COORDINADOR_SECUNDARIA"
      && user.role !== "COORDINADOR_PREPARATORIA"
    ){
      navigate('/inicio');
    }
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Administrar" title="Alumnos" />
      
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
            <Button color="primary" onClick={toggle}>Nuevo Alumno</Button>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                {
                  getUsers ?                    
                    <div className="loader-box">
                      <div className="loader-1"></div>
                    </div>
                  :
                    <DataTable
                      columns={columns}
                      striped={true}
                      center={true}
                      data={filteredItems}
                      subHeader
                      pagination
                      subHeaderComponent={subHeaderComponent}
                      noDataComponent={<b style={{padding:10}}>No se encontraron Alumno(a)s</b>}
                    />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      
      <Modal isOpen={modal} toggle={toggle} size="lg" style={{minWidth:'50%'}}>
        <ModalHeader toggle={toggle}>{isEditingUser ? 'Editar Alumno' : 'Crear Nuevo Alumno' }
          <button className="btn-close invisible" type="button">
            <span aria-hidden="true" className="visible" onClick={toggle}></span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingUser ? onSubmitPatchUser : onSubmitCreateUser)} style={{padding:20}}>
            <Row className="g-3 dflex mb-3">
              <Col md="12">
                <Label className="form-label font-weight-bold" for="validationCustom01">
                  NIA
                </Label>
                <input className="form-control" id="validationCustom01" type="text" placeholder="CLAVE NIA" name="nia" {...register("nia", { required: true })} />
                <span>{errors.names && "CLAVE NIA requerida para estudiantes"}</span>
              </Col>
              <Col md="6">
                <Label className="form-label font-weight-bold" for="validationCustom01">
                  Nombre(s)
                </Label>
                <input className="form-control" id="validationCustom01" type="text" placeholder="Nombres" name="names" {...register("names", { required: true })} />
                <span>{errors.names && "Nombre(s) requerido"}</span>
              </Col>
              <Col md="6">
                <Label className="form-label font-weight-bold" for="validationCustom02">
                  Apellidos
                </Label>
                <input className="form-control" id="validationCustom02" type="text" placeholder="Apellidos" name="second_names" {...register("second_names", { required: true })} />
                <span>{errors.second_names && "Apellidos requerido"}</span>
              </Col>
            </Row>
            <Row className="g-3 dflex mb-3">
              <Col md="4">
                <Label className="form-label font-weight-bold" for="validationCustom03">
                  Tipo Sangre
                </Label>
                <input className="form-control" id="validationCustom03" type="text" placeholder="Tipo Sangre" name="blood_type" {...register("blood_type")} />
              </Col>
              <Col md="8">
                <Label className="form-label font-weight-bold" for="validationCustom04">
                  Prescripciones Médicas
                </Label>
                <textarea className="form-control" id="validationCustom04" type="text" placeholder="Prescripciones Médicas" name="medicals" {...register("medicals")} />
              </Col>
            </Row>
            <Row className="g-3 dflex mb-3">
              <Col md="12">
                <Label className="form-label font-weight-bold">
                  Grado
                </Label>
                <Typeahead
                  id="gradeFinder"
                  clearButton
                  defaultSelected={gradeSelected}
                  labelKey="name"
                  options={dataGrades}
                  placeholder="Elegir Grado..."
                  onChange={(e)=>{setgradeSelected(e)}}
                />
              </Col>
            </Row>
            {
              isEditingUser ? 
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold">
                      Personas autorizadas para recoger Alumno(a) {addingPeople ? <span onClick={handleCancelAddNewAuthPeople} style={{color:'red', cursor:'pointer'}}>Cancelar</span> : <span onClick={handleAddNewAuthPeople} style={{color:'blue', cursor:'pointer'}}>Agregar</span>}
                    </Label>
                    {addingPeople ?
                    <div className="row" style={{backgroundColor:'#ddd', padding:5}}>
                      <div className="col-md-3">
                        <input type="text" className="form-control" placeholder="Nombre" value={namePicker} onChange={(e)=>{setNamePicker(e.target.value)}}/>
                      </div>
                      <div className="col-md-3">
                        <input type="text" className="form-control" placeholder="Parentesco" value={relationPicker} onChange={(e)=>{setRelationPicker(e.target.value)}}/>
                      </div>
                      <div className="col-md-4">
                        <input type="file" className="form-control" accept="image/*" onChange={(e)=>{setFilePicker(e.target.files[0])}}/>
                      </div>
                      <div className="col-md-2">
                        {!addingPicker? <span onClick={createPicker} style={{marginTop:10, color:'blue', cursor:'pointer'}}>Añadir</span> : <span style={{marginTop:10, color:'gray'}}>Cargando ...</span>}
                      </div>
                    </div> : null }
                    <div style={{marginTop:10, borderRadius: '10px', padding:10}}>
                      {
                        authPick.length > 0 ? 
                          authPick.map((person)=>{
                            return(
                              <div key={person.uuid} className="row" style={{marginBottom:10}}>
                                <div className="col-md-2"><a href={`${person.document}`} target="_blank"><img src={`${person.document}`} style={{maxWidth:80}} /></a>
                                </div>
                                <div className="col-md-10">
                                  <p><b>Nombre</b>: {person.name} <b>Parentesco</b>: {person.relation} {!removingPicker ? <span onClick={()=>handleRemoveAuthPicker(person)} style={{color:'red', cursor:'pointer'}}>Quitar</span> : null}</p>
                                </div>
                              </div>
                            )
                          })
                        : null
                      }
                    </div>
                  </Col>
                </Row>
              : null
            }
            <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
              {
                isEditingUser ? 
                  <Button disabled={patchingUser} className="btn btn-primary" style={{width:200}} color="primary">{"Guardar"}</Button> 
                : 
                  <Button disabled={creatingUser} className="btn btn-primary" style={{width:200}} color="primary">{"Crear"}</Button> 
              }
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalStudent} toggle={toggleStudent} size="lg" style={{minWidth:'50%'}}>
        <ModalHeader toggle={toggleStudent}>Perfil del Alumno
          <button className="btn-close invisible" type="button">
            <span aria-hidden="true" className="visible" onClick={toggleStudent}></span>
          </button>
        </ModalHeader>
        <ModalBody>
          {gettingUserProfile ?
            'Cargando...'
          :
            <div className="container">
              <h4><b>NIA: {userData.nia}</b></h4>
              <h4><b>{userData.names} {userData.second_names}</b></h4>
              <h5><b>Grado</b>: {userData.grade ? userData.grade[0].name : null}</h5>
              <h6><b>Tipo Sangre</b>: {userData.blood_type}</h6>
              <h6><b>Prescripciones médicas</b>: {userData.medicals}</h6>
              <hr></hr>
              <h5>Padres o Tutores:</h5>
              {
                tutorsData.length > 0 ? 
                  tutorsData.map((tutor)=>{
                    return(
                      <div style={{background:'#f4f4f4', padding:10, borderRadius:10, marginBottom:10, width:'100%'}}>
                        <span style={{fontSize:'1rem', fontWeight: 'bold'}}>Nombre: {tutor.names + ' ' + tutor.second_names} <br/>Teléfono: {tutor.phone_1}<br/> Dirección: {tutor.address}</span> 
                      </div>
                    );
                  })
                : null
              }
              <hr></hr>
              <h5>Personas Autorizadas para recoger al Alumno(a):</h5>
              {
                userData.people_auth_pick ? 
                  userData.people_auth_pick.map(person=>{
                    return(
                      <div key={person.uuid} className="row" style={{marginBottom:10}}>
                        <div className="col-md-6"><a href={`${person.document}`} target="_blank"><img src={`${person.document}`} style={{maxWidth:300}} /></a>
                        </div>
                        <div className="col-md-6">
                          <p><b>Nombre</b>: {person.name}<br/>
                          <b>Parentesco</b>: {person.relation}</p>
                        </div>
                      </div>
                    )
                  })
                : null
              }
            </div>
          }
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default Student;
