import React, { Fragment, useState, useMemo } from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Form,
} from "reactstrap";
import SweetAlert from "sweetalert2";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_ABSENCE, API_USER } from "../../../redux/apiRoutes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from 'react-datepicker';
import es from "date-fns/locale/es";
import { v4 as uuidv4 } from 'uuid';
import FilterComponent from "../../common/filter";

function Absence(props) {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const toggle = () => {setModal(!modal); reset(); setEditinAbsence(false); clearModal()};

  const [modalChangeStatus, setModalChangeStatus] = useState(false);
  const toggleChangeStatus = () => {setModalChangeStatus(!modalChangeStatus);};

  const clearModal = () => {
    setChildSelected([]);
    setstartDate(new Date());
    setendDate(new Date());
  }

  const handleEditAction = (absence) => {
    toggle();
    setEditinAbsence(true);
    setCurrentAbsence(absence);
    dataChilds.map((child)=>{
      if(child.uuid===absence.studentUuid){
        setChildSelected([child]);
      }
    });
    setTypeAbsence(absence.type);

    setTimeout(()=>{
      setValue('type', absence.type);
      setValue('description', absence.description);
      setstartDate(new Date(absence.startDate.split('T')[0]));
      setendDate(absence.endDate ? new Date(absence.endDate.split('T')[0]) : '');
    },500)
  }

  const [currentAbsence, setCurrentAbsence] = useState({});
  const [currentAbsenceStatus, setCurrentAbsenceStatus] = useState({});
  const handleChangeStatus = (absence) => {
    toggleChangeStatus();
    if(absence.endDate!==''){
      setendDate(new Date(absence.endDate));
    }
    setCurrentAbsence(absence);
    setCurrentAbsenceStatus(absence.status);
  }

  const [isChangeAbsenceStatus, setIsChangeAbsenceStatus] = useState(false);
  const changeAbsenceStatus = (absence) => {
    absence.status = currentAbsenceStatus;
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se cambiará el estado del reporte a: ${estadoString(absence.status)}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        setIsChangeAbsenceStatus(true);
        let patchData = {status:absence.status};
        if(absence.type==='HEALTH' && absence.status === 'CLOSED'){
          patchData = {status:absence.status,justify:true};
        }
        if(absence.type==='HEALTH' && (absence.status === 'JUSTIFY_PENDING' || absence.status === 'INFORMED' || absence.status === 'OPENED')){
          patchData = {status:absence.status,justify:false};
        }
        patchData.endDate = endDate;
        await axios.patch(`${API_ABSENCE}/status/${absence.uuid}`,patchData).then(response => {
          getAbsenceList();
          toggleChangeStatus();
          setIsChangeAbsenceStatus(false);
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al actualizar el estado del reporte por ausencia: " + error
            );
          }, 200);
          setIsChangeAbsenceStatus(false);
        });
      }
    });
  }

  const handleDeleteAction = (data) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminará el reporte de ausencia de: ${data.studentName}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await axios.delete(`${API_ABSENCE}/${data.uuid}`).then(response => {
          getAbsenceList();
          SweetAlert.fire("¡Listo!", "reporte eliminado", "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar el reporte: " + error
            );
          }, 200);
        });
      }
    });
  }

  const justify = (required) =>{
    if(required){
      return <b className="text-success">Entregado</b>;
    }else{
      return <b className="text-danger">Pendiente</b>;
    }
  }

  const estado = (status) =>{
    switch(status){
      case 'OPENED':
        return <div><span className="badge badge-success">Recibida</span> <br/></div>;
      case 'INFORMED':
        return <div><span className="badge badge-secondary">Enterados</span> <br/></div>;
      case 'JUSTIFY_PENDING':
        return <div><span className="badge badge-danger">Esperando</span> <br/></div>;
      case 'CLOSED':
        return <div><span className="badge badge-dark">Concluida</span> <br/></div>;
    }
  }

  const estadoString = (status) =>{
    switch(status){
      case 'OPENED':
        return 'RECIBIDO';
      case 'INFORMED':
        return 'ENTERADOS';
      case 'JUSTIFY_PENDING':
        return 'ESPERANDO JUSTIFICANTE';
      case 'CLOSED':
        return 'FINALIZADO';
    }
  }

  const type = (type) => {
    switch(type){
      case 'HEALTH':
        return <b>SALUD</b>;
      case 'VACATION':
        return <b>VACACIONES</b>;
      case 'COMPETITION':
        return <b>COMPETENCIAS</b>;
    }
  }

  const columns = [
    {
      name: "Alumno(a)",
      selector: (row) => <b>{row.studentName}</b>,
      sortable: true,
      center: true,
      width: '150px'
    },
    {
      name: "Salón",
      selector: (row) => row.grade,
      sortable: true,
      center: true,
      width: '100px'
    },
    {
      name: "Nivel",
      selector: (row) => row.level,
      sortable: true,
      center: true,
      width: '150px'
    },
    {
      name: "Motivo",
      selector: (row) => type(row.type),
      sortable: true,
      center: true,
      width: '150px'
    },
    {
      name: "Estado",
      selector: (row) => estado(row.status),
      sortable: true,
      center: true,
    },
    {
      name: "Justificante",
      selector: (row) => row.applyJustify ? justify(row.justify) : 'N/A',
      sortable: true,
      center: true,
    },
    {
      name: "Fecha Inicio",
      selector: (row) => row.startDate ? new Date(row.startDate).getDate() + '-' + (new Date(row.startDate).getMonth() + 1) + '-' + new Date(row.startDate).getFullYear() : '--',
      sortable: true,
      center: true,
    },
    {
      name: "Fecha Fin",
      selector: (row) => row.endDate ? new Date(row.endDate).getDate() + '-' + (new Date(row.endDate).getMonth() + 1) + '-' + new Date(row.endDate).getFullYear() : '--',
      sortable: true,
      center: true,
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                          {row.status === "OPENED" && user.uuid === row.tutorUuid ? <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleEditAction(row)}}><i className="fa fa-pencil"></i></Button> : null}
                          {editPermissions ? <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleChangeStatus(row)}}><i className="fa fa-pencil"></i></Button> : null}
                          {row.status === "OPENED" && user.uuid === row.tutorUuid ? <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleDeleteAction(row)}}><i className="fa fa-trash"></i></Button> : null}
                        </div>,
      width: '160px'
    },
  ];

  const [typeAbsence, setTypeAbsence] = useState('');
  const [dataAbsence, setDataAbsence] = useState([]);
  const [getAbsence, setGetAbsence] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const getAbsenceList = async () => {
    setGetAbsence(true);

    let queryRule = '';
    if(user.role==='TUTOR'){
      queryRule = `status=OPENED,INFORMED,JUSTIFY_PENDING,CLOSED&tutorUuid=${user.uuid}`;
    }
    
    if(user.role==='ADMIN' || user.role==='SISTEMAS' || user.role === "COORDINADOR_GENERAL"){
      setEditPermissions(true);
      queryRule = `status=OPENED,INFORMED,JUSTIFY_PENDING,CLOSED`;
    }

    if(user.role === "COORDINADOR_PREESCOLAR"){
      setEditPermissions(true);
      queryRule = `status=OPENED,INFORMED,JUSTIFY_PENDING,CLOSED&level=PREESCOLAR,MATERNAL`;
    }

    if(user.role === "COORDINADOR_PRIMARIA"){
      setEditPermissions(true);
      queryRule = `status=OPENED,INFORMED,JUSTIFY_PENDING,CLOSED&level=PRIMARIA`;
    }

    if(user.role === "COORDINADOR_SECUNDARIA"){
      setEditPermissions(true);
      queryRule = `status=OPENED,INFORMED,JUSTIFY_PENDING,CLOSED&level=SECUNDARIA`;
    }

    if(user.role === "COORDINADOR_PREPARATORIA"){
      setEditPermissions(true);
      queryRule = `status=OPENED,INFORMED,JUSTIFY_PENDING,CLOSED&level=PREPARATORIA`;
    }

    if(user.role === "DOCENT"){
      //getTechGrades(user.uuid);
      setEditPermissions(true);
      queryRule = `status=OPENED,INFORMED,JUSTIFY_PENDING,CLOSED`;
    }
    
    await axios.get(`${API_ABSENCE}?${queryRule}`).then(response => {
      if(response.data.docs.length>0){
        setDataAbsence(response.data.docs);
      }else{
        setDataAbsence([]);
      }
      setGetAbsence(false);
    }).catch(e => {
      setGetAbsence(false);
      toast.error("No se pudo obtener el listado de Alumnos: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [childSelected,setChildSelected] = useState([]);
  const [dataChilds,setdataChilds] = useState([]);
  const getChildsList = async () => {
    await axios.get(`${API_USER}?status=ACTIVE&uuid=${user.uuid}`).then(response => {
      if(response.data.docs.length>0){
          if(response.data.docs.length>0){
            if(response.data.docs[0].childs){
              const childs = []
              response.data.docs[0].childs.map(child=>{
                const addChild = {
                  uuid: child.uuid,
                  name: child.name
                }
                childs.push(addChild);
              });
              setdataChilds(childs);
            }
        }
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Estudiantes: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());

  const handleChangeStart = (date) => {
    setstartDate(date);
    setendDate(date);
  };
  const handleChangeEnd = (date) => {
    setendDate(date);
  };

  const {
    register,
    handleSubmit,
    unregister,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [sendingAbsence, setSendingAbsence] = useState(false);
  const sendAbsence = async (data) => {
    setSendingAbsence(true);

    if (data !== "") {
      if(childSelected.length<=0){
        toast.error("Indica el alumno(a) que tendrá la ausencia", {position: 'bottom-right', style:{color:'white'}});
        setSendingAbsence(false);
        return;
      }

      if(startDate==="" || startDate === null){
        toast.error("Ingresa fecha inicial de la ausencia", {position: 'bottom-right', style:{color:'white'}});
        setSendingAbsence(false);
        return;
      }

      data.uuid = uuidv4();
      data.tutorUuid = user.uuid;
      if(childSelected.length>0){data.studentUuid = childSelected[0].uuid;}; 
      data.status = 'OPENED';
      data.startDate = startDate;
      if(endDate !== ''){
        data.endDate = endDate;
      }

      await axios.post(`${API_ABSENCE}`,data).then(response => {
        if(response.data){
          toggle();
          getAbsenceList();
          reset();
          toast.success("¡Reporte de ausencia creado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setSendingAbsence(false);
      }).catch(e => {
        setSendingAbsence(false);
        toast.error("No se pudo reportar la ausencia: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [isEditingAbsence, setEditinAbsence] = useState(false);
  const [patchingAbsence, setPatchingAbsence] = useState(false);
  const patchAbsence = async (data) => {
    setPatchingAbsence(true);

    if(childSelected.length<=0){
      toast.error("Indica el alumno(a) que tendrá la ausencia", {position: 'bottom-right', style:{color:'white'}});
      setPatchingAbsence(false);
      return;
    }

    if(startDate==="" || startDate === null){
      toast.error("Ingresa fecha inicial de la ausencia", {position: 'bottom-right', style:{color:'white'}});
      setPatchingAbsence(false);
      return;
    }

    if(childSelected.length>0){data.studentUuid = childSelected[0].uuid;};
    data.startDate = startDate;
    console.log(endDate)
    if(endDate !== ''){
      data.endDate = endDate;
    }
    if(data.type === 'HEALTH'){
      data.applyJustify = true;
    }else{
      data.applyJustify = false;
    }

    await axios.patch(`${API_ABSENCE}/${currentAbsence.uuid}`,data).then(response => {
      getAbsenceList();
      toggle();
      setPatchingAbsence(false);
    }).catch(error => {
      setTimeout(() => {
        toast.error(
          "Error al actualizar el estado del reporte por ausencia: " + error
        );
      }, 200);
      setPatchingAbsence(false);
    });
  }

  useEffect(() => {
    getChildsList();
    getAbsenceList();
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "TUTOR"
      && user.role !== "COORDINADOR_GENERAL"
      && user.role !== "COORDINADOR_PREESCOLAR"
      && user.role !== "COORDINADOR_PRIMARIA"
      && user.role !== "COORDINADOR_SECUNDARIA"
      && user.role !== "COORDINADOR_PREPARATORIA"
      && user.role !== "DOCENT"
    ){
      navigate('/inicio');
    }
  }, [navigate]);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const filteredItems = dataAbsence.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <Fragment>
      <Breadcrumb parent="Herramientas" title="Ausencias" />
      <Container fluid={true}>
        {user.role === 'TUTOR' ?
          <Row>
            <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
              <Button color="primary" onClick={toggle}>Reportar Ausencia</Button>
            </Col>
          </Row>
        : null }

        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                <DataTable
                  columns={columns}
                  striped={true}
                  center={true}
                  data={filteredItems}
                  pagination
                  subHeader
                  subHeaderComponent={subHeaderComponent}
                  noDataComponent={<b style={{padding:10}}>¡Muy bien! No tienes reportes por Ausencia</b>}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>

      {user.role === 'TUTOR' ?
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>{isEditingAbsence ? 'Editar Reporte por Ausencia' : 'Reportar Nueva Ausencia' }
            <button className="btn-close invisible" type="button">
              <span aria-hidden="true" className="visible" onClick={toggle}></span>
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="container">
              <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingAbsence ? patchAbsence : sendAbsence)} style={{padding:20}}>
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold">
                      Alumno(a)
                    </Label>
                    <Typeahead
                      id="gradeFinder"
                      clearButton
                      defaultSelected={childSelected}
                      labelKey="name"
                      options={dataChilds}
                      placeholder="Elegir Hijo(a)..."
                      onChange={(e)=>{setChildSelected(e)}}
                    />
                  </Col>
                </Row>
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="validationCustom01">
                      Motivo
                    </Label>
                    <select className="form-control mb-1" id="validationCustom01" type="text" placeholder="City" {...register("type", { required: true })} onChange={(e)=>setTypeAbsence(e.target.value)}>
                      <option value="">-- Elegir --</option>
                      <option value="HEALTH">CAUSAS DE SALUD</option>
                      <option value="VACATION">VACACIONES</option>
                      <option value="COMPETITION">COMPETENCIA</option>
                      <option value="PERSONAL">ASUNTOS PERSONALES</option>
                    </select>
                    <span style={{color:'red'}}>{errors.type && "Elige el motivo de la ausencia"}</span>
                    <div className="invalid-feedback">{"Elige el motivo de la ausencia"}</div>
                  </Col>
                </Row>
                { typeAbsence === 'HEALTH' ?
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="validDescription">
                      Detalles Médicos
                    </Label>
                    <input className="form-control mb-1" id="validDescription" type="text" placeholder="Detalla el tipo de enfermedad o discapacidad" {...register("description", { required: true })} />
                    <span style={{color:'red'}}>{errors.description && "Este campo es requerido"}</span>
                  </Col>
                </Row> : <input className="form-control mb-1 hidden" type="text" placeholder="Detalla el tipo de enfermedad o discapacidad" />}
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="validationCustom03">
                      Fecha Inicio
                    </Label>                                
                      <DatePicker className="form-control digits"
                        selected={startDate}
                        onChange={handleChangeStart}
                        selectsStart
                        minDate={new Date()}
                        locale={es}
                        dateFormat={'dd/MM/yyyy'}
                      />
                  </Col>
                </Row>
                <Row className="g-3 dflex mb-4">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="validationCustom03">
                      Fecha Fin
                    </Label>     
                    <DatePicker className="form-control digits"
                      selected={endDate}
                      onChange={handleChangeEnd}
                      selectsEnd
                      minDate={startDate}
                      locale={es}
                      dateFormat={'dd/MM/yyyy'}
                    />
                  </Col>
                </Row>
                <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                {
                  isEditingAbsence ? 
                    <Button disabled={patchingAbsence} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                  : 
                    <Button disabled={sendingAbsence} className="btn btn-primary" style={{width:150}} color="primary">{"Enviar"}</Button> 
                }
                </Row>
              </Form>
            </div>
          </ModalBody>
        </Modal>
      : null }

      {user.role !== 'TUTOR' && user.role !== 'STUDENT' ?
        <Modal isOpen={modalChangeStatus} toggle={toggleChangeStatus}>
          <ModalHeader toggle={toggleChangeStatus}>Cambiar Estado de Ausencia
            <button className="btn-close invisible" type="button">
              <span aria-hidden="true" className="visible" onClick={toggleChangeStatus}></span>
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="container">
                <Row className="g-3 dflex mb-1">
                  <Col md="12">
                    <Label className="form-label font-weight-bold">
                      Alumno(a): <span className="text-secondary">{currentAbsence.studentName}</span>
                    </Label>
                  </Col>
                </Row>
                <Row className="g-3 dflex mb-1">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="validationCustom01">
                      Motivo de ausencia: <span className="text-secondary">{type(currentAbsence.type)}</span>
                    </Label>
                  </Col>
                </Row>
                {
                  currentAbsence.type === 'HEALTH' ?
                    <Row className="g-3 dflex mb-1">
                      <Col md="12">
                        <Label className="form-label font-weight-bold" for="validationCustom01">
                          Detalles Médicos: <span className="text-secondary">{currentAbsence.description}</span>
                        </Label>
                      </Col>
                    </Row>
                  : null
                }
                <Row className="g-3 dflex mb-1">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="validationCustom01">
                      Fecha Inicio: <span className="text-secondary">{Object.keys(currentAbsence).length > 0 ? currentAbsence.startDate.split('T')[0] : null}</span>
                    </Label>
                  </Col>
                </Row>
                <Row className="g-3 dflex mb-1">
                  <Col md="3">
                    <Label className="form-label font-weight-bold" for="validationCustom01">
                      Fecha Fin:
                    </Label>
                  </Col>
                  <Col md="9">
                    <DatePicker className="form-control digits"
                        selected={endDate}
                        onChange={handleChangeEnd}
                        selectsEnd
                        minDate={startDate}
                        locale={es}
                        dateFormat={'dd/MM/yyyy'}
                      />
                  </Col>
                </Row>
                <Row className="g-3 dflex mb-1">
                  <Col md="12">
                    <div style={{display:'flex'}}>
                      <Label className="form-label font-weight-bold" for="validationCustom01">
                        Estado:
                      </Label>
                      &nbsp; &nbsp;
                      <select className="form-control mb-1" defaultValue={currentAbsence.status} onChange={(e)=>setCurrentAbsenceStatus(e.target.value)}>
                        <option value="OPENED">RECIBIDA</option>
                        <option value="INFORMED">ENTERADOS</option>
                        {currentAbsence.type === 'HEALTH' ? <option value="JUSTIFY_PENDING">ESPERANDO JUSTIFICANTE</option> : null}
                        <option value="CLOSED">FINALIZADA</option>
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                  <Button disabled={isChangeAbsenceStatus} onClick={()=>changeAbsenceStatus(currentAbsence)} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                </Row>
            </div>
          </ModalBody>
        </Modal>
      : null }

    </Fragment>
  );
}

export default Absence;
