import React from "react";
import { Button } from "reactstrap";
import styled from "styled-components";

const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`
  width: 170px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  padding:7px;
  margin-top:-5px;
`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <Input
      id="search"
      type="text"
      placeholder="Buscar..."
      value={filterText}
      onChange={onFilter}
    />
    <Button color="dark rounded-6" style={{marginTop:-5, marginLeft:5, marginRight:-15}} onClick={onClear}>X</Button>
  </>
);

export default FilterComponent;
