import React from "react";
export const MENUITEMS = [
  {
    title: "Inicio",
    icon: <i className="pe-7s-home pe-lg"></i>,
    path: `${process.env.PUBLIC_URL}/inicio`,
    type: "sub",
    permissions: ['ADMIN','DOCENT','COORDINADOR_GENERAL','COORDINADOR_PREESCOLAR','COORDINADOR_PRIMARIA','COORDINADOR_SECUNDARIA','COORDINADOR_PREPARATORIA','RECEPCIONISTA','SISTEMAS','TUTOR'],
    active: true,
    bookmark: true,
    children: [ 
      { title: "Inicio", type: "sub" },
      {
        title: "Inicio",
        type: "link",
        path: `${process.env.PUBLIC_URL}/inicio`,
        permissions: false,
      },
    ],
  },
  {
    title: "Herramientas",
    icon: <i className="pe-7s-portfolio pe-lg"></i>,
    type: "sub",
    permissions: ['ADMIN','DOCENT','COORDINADOR_GENERAL','COORDINADOR_PREESCOLAR','COORDINADOR_PRIMARIA','COORDINADOR_SECUNDARIA','COORDINADOR_PREPARATORIA','SISTEMAS','TUTOR'],
    path: `${process.env.PUBLIC_URL}/herramientas`,
    active: false,
    bookmark: true,
    children: [
      { title: "Herramientas", type: "sub" },
      {
        title: "Tareas",
        type: "link",
        path: `${process.env.PUBLIC_URL}/tareas`,
        permissions: false,
      },
      {
        title: "Ausencias ",
        type: "link",
        path: `${process.env.PUBLIC_URL}/ausencias`,
        permissions: false,
      },
      {
        title: "Asistencias ",
        type: "link",
        path: `${process.env.PUBLIC_URL}/asistencias`,
        permissions: ['ADMIN','DOCENT','COORDINADOR_GENERAL','COORDINADOR_PREESCOLAR','COORDINADOR_PRIMARIA','COORDINADOR_SECUNDARIA','COORDINADOR_PREPARATORIA','SISTEMAS'],
      },
    ],
  },
  {
    title: "Administrar",
    icon: <i className="pe-7s-config"></i>,
    type: "sub",
    permissions: ['ADMIN','COORDINADOR_GENERAL','COORDINADOR_PREESCOLAR','COORDINADOR_PRIMARIA','COORDINADOR_SECUNDARIA','COORDINADOR_PREPARATORIA','RECEPCIONISTA','SISTEMAS'],
    path: `${process.env.PUBLIC_URL}/administrar`,
    active: true,
    children: [
      { title: "Form Controls", type: "sub" },
      {
        title: "Alumnos",
        type: "link",
        path: `${process.env.PUBLIC_URL}/alumnos`,
        permissions: ['ADMIN','COORDINADOR_GENERAL','COORDINADOR_PREESCOLAR','COORDINADOR_PRIMARIA','COORDINADOR_SECUNDARIA','COORDINADOR_PREPARATORIA','SISTEMAS','RECEPCIONISTA'],
      },
      {
        title: "Padres",
        type: "link",
        path: `${process.env.PUBLIC_URL}/padres`,
        permissions: ['ADMIN','COORDINADOR_GENERAL','SISTEMAS'],
      },
      {
        title: "Maestros",
        type: "link",
        path: `${process.env.PUBLIC_URL}/maestros`,
        permissions: ['ADMIN','COORDINADOR_GENERAL','COORDINADOR_PREESCOLAR','COORDINADOR_PRIMARIA','COORDINADOR_SECUNDARIA','COORDINADOR_PREPARATORIA','SISTEMAS'],
      },
      {
        title: "Personal",
        type: "link",
        path: `${process.env.PUBLIC_URL}/personal`,
        permissions: ['ADMIN','COORDINADOR_GENERAL','SISTEMAS'],
      },
      {
        title: "Grados",
        type: "link",
        path: `${process.env.PUBLIC_URL}/grados`,
        permissions: ['ADMIN','COORDINADOR_GENERAL','COORDINADOR_PREESCOLAR','COORDINADOR_PRIMARIA','COORDINADOR_SECUNDARIA','COORDINADOR_PREPARATORIA','SISTEMAS'],
      },
    ],
  },
];
