import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { API_GRADE, API_USER } from "../../../redux/apiRoutes";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Form,
} from "reactstrap";

import { useSelector } from "react-redux";
import axios from "axios";
import { useMemo } from "react";
import FilterComponent from "../../common/filter";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";

function Docent(props) {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [modal, setModal] = useState(false);
  const [dataUsers, setDataUsers] = useState([]);

  const toggle = () => {setModal(!modal); reset(); setEditingUser(false); setgradesSelected([]); setAssignaturesSelected([]);};

  const [getUsers, setGetUsers] = useState(false);
  const getUsersList = async () => {
    setGetUsers(true);

    await axios.get(`${API_USER}?status=ACTIVE,DISABLED&role=DOCENT`).then(response => {
      if(response.data.docs.length>0){
        setDataUsers(response.data.docs);
      }
      setGetUsers(false);
    }).catch(e => {
      setGetUsers(false);
      toast.error("No se pudo obtener el listado de Maestros: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [isEditingUser, setEditingUser] = useState(false);
  const [userData, setuserData] = useState(false);
  const handleViewUser = (user) => {
    toggle();
    setEditingUser(true);
    setuserData(user);
    setAssignaturesSelected(user.assignatures);
    setgradesSelected(user.tech_grades);

    setTimeout(()=>{
      setValue('names', user.names);
      setValue('second_names', user.second_names);
      setValue('user_nickname', user.user_nickname);
      setValue('phone_1', user.phone_1);
      setValue('address', user.address);
      setValue('city', user.city);
      setValue('state', user.state);
    },500)
  }

  const handleStatusUser = (user,status) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se ${status === 'DISABLED' ? 'desactivará' : 'activará'} el usuario: ${user.names}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await axios.patch(`${API_USER}/${user.uuid}`,{status:status}).then(response => {
          getUsersList();
          SweetAlert.fire("¡Listo!", `usuario ${status === 'DISABLED' ? 'desactivado' : 'activado'}`, "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar productos: " + error
            );
          }, 200);
        });
      }
    });
  }

  const handleDeleteUser = (user) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminara el usuario: ${user.names}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await axios.delete(`${API_USER}/${user.uuid}`).then(response => {
          getUsersList();
          SweetAlert.fire("¡Listo!", "usuario eliminado", "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar productos: " + error
            );
          }, 200);
        });
      }
    });
  }

  const columns = [
    {
      name: "Nombres",
      selector: (row) => <b>{row.names}</b>,
      sortable: true,
      center: true,
      width: '200px'
    },
    {
      name: "Apellidos",
      selector: (row) => <b>{row.second_names}</b>,
      sortable: true,
      center: true,
    },
    {
      name: "Teléfono",
      selector: (row) => row.phone_1,
      center: true,
    },
    {
      name: "Grados",
      selector: (row) => <div>
                          {
                            row.tech_grades !== undefined && row.tech_grades.length > 0 ?
                              row.tech_grades.map((grade)=>{
                                return(<div><span className="badge badge-dark">{grade.name}</span> <br/></div>)
                              })
                            : null
                          }
                        </div>,
      width: '150px',
      center: true,
    },
    {
      name: "Asignaturas",
      selector: (row) => <div>
                            {
                              row.assignatures !== undefined && row.assignatures.length > 0 ?
                                row.assignatures.map((assignature)=>{
                                  return(<div><span className="badge badge-info">{assignature}</span> <br/></div>)
                                })
                              : null
                            }
                          </div>,
      width: '150px',
      center: true,
    },
    {
      name: "Estado",
      selector: (row) => row.status === 'DISABLED' ? <span className="badge badge-warning">DESACTIVADO</span> : <span className="badge badge-success">ACTIVO</span>,
      center: true,
      sortable: true,
    },
    {
      name: "Creado",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
      center: true,
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleViewUser(row)}}><i className="fa fa-pencil"></i></Button>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleStatusUser(row, row.status === 'DISABLED' ? 'ACTIVE' : 'DISABLED')}}><i className="fa fa-power-off"></i></Button>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleDeleteUser(row)}}><i className="fa fa-trash"></i></Button>
                        </div>,
      center: true,
      width: '180px'
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const filteredItems = dataUsers.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [creatingUser, setCreatingUser] = useState(false);
  const onSubmitCreateUser = async (user) => {
    setCreatingUser(true);

    if (user !== "") {
      user.uuid = uuidv4();
      user.role = 'DOCENT';
      user.status = 'ACTIVE';
      user.email = user.user_nickname;
      if(assignaturesSelected.length>0){user.assignatures = assignaturesSelected}
      if(gradesSelected.length>0){user.tech_grades = gradesSelected}

      await axios.post(`${API_USER}`,user).then(response => {
        if(response.data){
          toggle();
          getUsersList();
          setAssignaturesSelected([]);
          reset();
          toast.success("Maestro creado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setCreatingUser(false);
      }).catch(e => {
        setCreatingUser(false);
        toast.error("No se pudo crear el Maestro: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [patchingUser, setPatchingUser] = useState(false);
  const onSubmitPatchUser = async (user) => {
    setPatchingUser(true);

    if (user !== "") {
      user.email = user.user_nickname;
      if(assignaturesSelected && assignaturesSelected.length>0){user.assignatures = assignaturesSelected}else{user.assignatures = []}
      if(gradesSelected && gradesSelected.length>0){user.tech_grades = gradesSelected}else{user.tech_grades = []}

      await axios.patch(`${API_USER}/${userData.uuid}`,user).then(response => {
        if(response.data){
          toggle();
          getUsersList();
          setAssignaturesSelected([]);
          reset();
          toast.success("Maestro actualizado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setPatchingUser(false);
      }).catch(e => {
        setPatchingUser(false);
        toast.error("No se pudo actualizar el Maestro: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [assignaturesSelected,setAssignaturesSelected] = useState([]);
  const dataAssignatures = [
    'MATEMATICAS',
    'ESPAÑOL',
    'FORCE',
    'INGLES',
    'EDUCACION EMOCIONAL',
    'ARTE',
    'MUSICA',
    'FISICA',
    'QUIMICA',
    'BIOLOGIA',
    'GEOGRAFIA',
    'HISTORIA',
    'HISTORIA DE MEXICO',
    'HISTORIA UNIVERSAL',
    'CONCIENCIA PLENA',
    'FOMENTO A LA LECTURA',
    'FILOSOFIA',
    'METODOLOGIA DE LA INVESTIGACION',
    'LECTURA Y REDACCION',
    'ETICA',
    'EDUCACION FISICA',
    'ATENCION PLENA',
    'CIENCIAS SOCIALES',
  ]

  const [gradesSelected,setgradesSelected] = useState([]);
  const [dataGrades,setdataGrades] = useState([]);
  const getGradesList = async () => {

    await axios.get(`${API_GRADE}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        const grades = []
        response.data.docs.map(grade=>{
          const addGrade = {
            uuid: grade.uuid,
            name: grade.name + '-' + grade.level
          }
          grades.push(addGrade);
        });
        setdataGrades(grades);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Grados escolares: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  useEffect(() => {
    getUsersList();
    getGradesList();
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"  
      && user.role !== "COORDINADOR_GENERAL"
      && user.role !== "COORDINADOR_PREESCOLAR"
      && user.role !== "COORDINADOR_PRIMARIA"
      && user.role !== "COORDINADOR_SECUNDARIA"
      && user.role !== "COORDINADOR_PREPARATORIA"
    ){
      navigate('/inicio');
    }
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Administrar" title="Maestros" />
      
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
            <Button color="primary" onClick={toggle}>Nuevo Maestro</Button>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                {
                  getUsers ?
                    <div className="loader-box">
                      <div className="loader-1"></div>
                    </div>
                  :
                    <DataTable
                      columns={columns}
                      striped={true}
                      center={true}
                      data={filteredItems}
                      subHeader
                      pagination
                      subHeaderComponent={subHeaderComponent}
                      noDataComponent={<b style={{padding:10}}>No se encontraron Maestros</b>}
                    />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      
      <Modal isOpen={modal} toggle={toggle} size="lg" style={{minWidth:'50%'}}>
        <ModalHeader toggle={toggle}>{isEditingUser ? 'Editar Maestro' : 'Crear Nuevo Maestro' }
          <button className="btn-close invisible" type="button">
            <span aria-hidden="true" className="visible" onClick={toggle}></span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingUser ? onSubmitPatchUser : onSubmitCreateUser)} style={{padding:20}}>
            <Row className="g-3 dflex mb-3">
              <Col md="6">
                <Label className="form-label font-weight-bold" for="validationCustom01">
                  Nombre(s)
                </Label>
                <input className="form-control" id="validationCustom01" type="text" placeholder="Nombres" name="names" {...register("names", { required: true })} />
                <span>{errors.names && "Nombre(s) requerido"}</span>
              </Col>
              <Col md="6">
                <Label className="form-label font-weight-bold" for="validationCustom02">
                  Apellidos
                </Label>
                <input className="form-control" id="validationCustom02" type="text" placeholder="Apellidos" name="second_names" {...register("second_names", { required: true })} />
                <span>{errors.second_names && "Apellidos requerido"}</span>
              </Col>
            </Row>
            <Row className="g-3 dflex mb-3">
              <Col md="6">
                <Label className="form-label font-weight-bold" for="validationCustom03">
                  Email (Usuario)
                </Label>
                <input className="form-control" id="validationCustom03" type="email" placeholder="Usuario" name="user_nickname" {...register("user_nickname", { required: true })} />
                <span>{errors.user_nickname && "Email requerido"}</span>
              </Col>
              <Col md="6">
                <Label className="form-label font-weight-bold" for="validationCustom04">
                  Contraseña
                </Label>
                <input className="form-control" id="validationCustom04" type="text" placeholder="Contraseña" name="password" {...register("password")} />
                <span>{errors.password && "Contraseña requerido"}</span>
              </Col>
            </Row>
            <Row className="g-3 dflex mb-3">
              <Col md="6">
                <Label className="form-label font-weight-bold" for="validationCustom06">
                  Telefono
                </Label>
                <input className="form-control" id="validationCustom06" type="text" placeholder="Telefono" name="phone_1" {...register("phone_1", { required: true })} />
                <span>{errors.phone_1 && "Telefono requerido"}</span>
              </Col>
            </Row>
            <Row className="g-3 dflex mb-3">
              <Col md="4">
                <Label className="form-label font-weight-bold" for="validationCustom07">
                  Dirección
                </Label>
                <input className="form-control" id="validationCustom07" type="text" placeholder="Dirección" name="address" {...register("address")} />
              </Col>
              <Col md="4">
                <Label className="form-label font-weight-bold" for="validationCustom08">
                  Ciudad
                </Label>
                <input className="form-control" id="validationCustom08" type="text" placeholder="Ciudad" name="city" {...register("city")} />
              </Col>
              <Col md="4">
                <Label className="form-label font-weight-bold" for="validationCustom09">
                  Estado
                </Label>
                <input className="form-control" id="validationCustom09" type="text" placeholder="Estado" name="state" {...register("state")} />
              </Col>
            </Row>
            <Row className="g-3 dflex mb-3">
              <Col md="12">
                <Label className="form-label font-weight-bold">
                  Asignaturas
                </Label>
                <Typeahead
                  id="assignatureFinder"
                  clearButton
                  defaultSelected={assignaturesSelected}
                  multiple
                  options={dataAssignatures}
                  placeholder="Elegir Asignaturas..."
                  onChange={(e)=>{setAssignaturesSelected(e)}}
                />
              </Col>
            </Row>
            <Row className="g-3 dflex mb-3">
              <Col md="12">
                <Label className="form-label font-weight-bold">
                  Grados
                </Label>
                <Typeahead
                  id="gradeFinder"
                  clearButton
                  defaultSelected={gradesSelected}
                  labelKey="name"
                  multiple
                  options={dataGrades}
                  placeholder="Elegir Grado..."
                  onChange={(e)=>{setgradesSelected(e)}}
                />
              </Col>
            </Row>
            <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
              {
                isEditingUser ? 
                  <Button disabled={patchingUser} className="btn btn-primary" style={{width:200}} color="primary">{"Guardar"}</Button> 
                : 
                  <Button disabled={creatingUser} className="btn btn-primary" style={{width:200}} color="primary">{"Crear"}</Button> 
              }
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default Docent;
