import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = (props) => {
  const abortController = new AbortController();

  useEffect(() => {
    const color = localStorage.getItem("color");
    document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);
    // app.auth().onAuthStateChanged(setCurrentUser);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };
  }, [abortController]);

  return props.token !== '' ?
      <Outlet />
    :
      <Navigate to={`${process.env.PUBLIC_URL}/login`} />
    ;
};

export default PrivateRoute;
