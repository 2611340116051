import React from "react";

// dashboard
import Home from "../component/general/dashboard/home";
import HomeWork from "../component/general/dashboard/homework";
import Absence from "../component/general/dashboard/absence";
import Student from "../component/general/dashboard/student";
import Docent from "../component/general/dashboard/docent";
import Grades from "../component/general/dashboard/grades";
import Tutor from "../component/general/dashboard/tutor";
import Employee from "../component/general/dashboard/employee";
import Assists from "../component/general/dashboard/assists";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/inicio`,
    Component: <Home />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tareas`,
    Component: <HomeWork />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ausencias`,
    Component: <Absence />,
  },
  {
    path: `${process.env.PUBLIC_URL}/asistencias`,
    Component: <Assists />,
  },
  {
    path: `${process.env.PUBLIC_URL}/alumnos`,
    Component: <Student />,
  },
  {
    path: `${process.env.PUBLIC_URL}/padres`,
    Component: <Tutor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/maestros`,
    Component: <Docent />,
  },
  {
    path: `${process.env.PUBLIC_URL}/grados`,
    Component: <Grades />,
  },
  {
    path: `${process.env.PUBLIC_URL}/personal`,
    Component: <Employee />,
  },
];
