import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { API_GRADE } from "../../../redux/apiRoutes";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Form,
} from "reactstrap";

import { useSelector } from "react-redux";
import axios from "axios";
import { useMemo } from "react";
import FilterComponent from "../../common/filter";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router-dom";

function Grades(props) {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [modal, setModal] = useState(false);
  const [dataGrades, setDataGrades] = useState([]);

  const toggle = () => {setModal(!modal); reset(); setIsEditingGrade(false);};

  const [getGrades, setGetGrades] = useState(false);
  const getGradesList = async () => {
    setGetGrades(true);

    await axios.get(`${API_GRADE}?status=ACTIVE,DISABLED`).then(response => {
      if(response.data.docs.length>0){
        setDataGrades(response.data.docs);
      }
      setGetGrades(false);
    }).catch(e => {
      setGetGrades(false);
      toast.error("No se pudo obtener el listado de Grados: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [isEditingGrade, setIsEditingGrade] = useState(false);
  const [dataGrade, setDataGrade] = useState(false);
  const handleViewGrade = (grade) => {
    toggle();
    setIsEditingGrade(true);
    setDataGrade(grade);
    setTimeout(()=>{
      setValue('name', grade.name);
      setValue('level', grade.level);
    },500)
  }

  const handleStatusGrade = (grade,status) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se ${status === 'DISABLED' ? 'desactivará' : 'activará'} el grado: ${grade.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await axios.patch(`${API_GRADE}/${grade.uuid}`,{status:status}).then(response => {
          getGradesList();
          SweetAlert.fire("¡Listo!", `grado ${status === 'DISABLED' ? 'desactivado' : 'activado'}`, "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar productos: " + error
            );
          }, 200);
        });
      }
    });
  }

  const handleDeleteGrade = (grade) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminara el grado: ${grade.name}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await axios.delete(`${API_GRADE}/${grade.uuid}`).then(response => {
          getGradesList();
          SweetAlert.fire("¡Listo!", "grado eliminado", "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar productos: " + error
            );
          }, 200);
        });
      }
    });
  }

  const columns = [
    {
      name: "Nombre Grado",
      selector: (row) => <b>{row.name}</b>,
      sortable: true,
      center: true,
      width: '200px'
    },
    {
      name: "Nivel",
      selector: (row) => <b>{row.level}</b>,
      sortable: true,
      center: true,
    },
    // {
    //   name: "Alumnos",
    //   selector: (row) => 25,
    //   center: true,
    // },
    // {
    //   name: "Maestros",
    //   selector: (row) => 4,
    //   center: true,
    // },
    {
      name: "Creado",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
      center: true,
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleViewGrade(row)}}><i className="fa fa-pencil"></i></Button>
                          {/* <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleStatusGrade(row, row.status === 'DISABLED' ? 'ACTIVE' : 'DISABLED')}}><i className="fa fa-power-off"></i></Button> */}
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}}onClick={()=>{handleDeleteGrade(row)}}><i className="fa fa-trash"></i></Button>
                        </div>,
      center: true,
      width: '180px'
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const filteredItems = dataGrades.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [creatingGrade, setCreatingGrade] = useState(false);
  const onSubmitCreateGrade = async (grade) => {
    setCreatingGrade(true);

    if (grade !== "") {
      grade.uuid = uuidv4();
      grade.status = 'ACTIVE';

      await axios.post(`${API_GRADE}`,grade).then(response => {
        if(response.data){
          toggle();
          getGradesList();
          reset();
          toast.success("Grado creado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setCreatingGrade(false);
      }).catch(e => {
        setCreatingGrade(false);
        toast.error("No se pudo crear el Grado: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [patchingGrade, setPatchingGrade] = useState(false);
  const onSubmitPatchGrade = async (grade) => {
    setPatchingGrade(true);

    if (grade !== "") {
      if(grade.email === ''){delete(grade.email)}
      if(grade.password === ''){delete(grade.password)}

      await axios.patch(`${API_GRADE}/${dataGrade.uuid}`,grade).then(response => {
        if(response.data){
          toggle();
          getGradesList();
          reset();
          toast.success("Grado actualizado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setPatchingGrade(false);
      }).catch(e => {
        setPatchingGrade(false);
        toast.error("No se pudo actualizar el Grado: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  useEffect(() => {
    getGradesList();
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"  
      && user.role !== "COORDINADOR_GENERAL"
      && user.role !== "COORDINADOR_PREESCOLAR"
      && user.role !== "COORDINADOR_PRIMARIA"
      && user.role !== "COORDINADOR_SECUNDARIA"
      && user.role !== "COORDINADOR_PREPARATORIA"
    ){
      navigate('/inicio');
    }
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Administrar" title="Grados" />
      
      <Container fluid={true}>
        <Row>
          <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
            <Button color="primary" onClick={toggle}>Nuevo Grado</Button>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="data-tables">
                {
                  getGrades ?
                    <div className="loader-box">
                      <div className="loader-1"></div>
                    </div>
                  :
                    filteredItems.length > 0 ?
                      <DataTable
                        columns={columns}
                        striped={true}
                        center={true}
                        data={filteredItems}
                        subHeader
                        pagination
                        subHeaderComponent={subHeaderComponent}
                      />
                    : 'No hay Grados registrados'
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      
      <Modal isOpen={modal} toggle={toggle} size="lg" style={{minWidth:'50%'}}>
        <ModalHeader toggle={toggle}>{isEditingGrade ? 'Editar Grado' : 'Crear Nuevo Grado' }
          <button className="btn-close invisible" type="button">
            <span aria-hidden="true" className="visible" onClick={toggle}></span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingGrade ? onSubmitPatchGrade : onSubmitCreateGrade)} style={{padding:20}}>
            <Row className="g-3 dflex mb-3">
              <Col md="6">
                <Label className="form-label font-weight-bold" for="validationCustom01">
                  Nombre del Grado
                </Label>
                <input className="form-control" id="validationCustom01" type="text" placeholder="Nombre del Grado" name="name" {...register("name", { required: true })} />
                <span>{errors.name && "Nombre Grado requerido"}</span>
              </Col>
              <Col md="6">
                <Label className="form-label font-weight-bold" for="validationCustom02">
                  Nivel
                </Label>
                <select className="form-control" id="validationCustom02" type="text" placeholder="Apellidos" name="level" {...register("level", { required: true })} >
                  <option value="">-- Elegir --</option>
                  <option value="MATERNAL">MATERNAL</option>
                  <option value="PREESCOLAR">PREESCOLAR</option>
                  <option value="PRIMARIA">PRIMARIA</option>
                  <option value="SECUNDARIA">SECUNDARIA</option>
                  <option value="PREPARATORIA">PREPARATORIA</option>
                </select>
                <span>{errors.level && "Nivel de Grado requerido"}</span>
              </Col>
            </Row>
            <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
              {
                isEditingGrade ? 
                  <Button disabled={patchingGrade} className="btn btn-primary" style={{width:200}} color="primary">{"Guardar"}</Button> 
                : 
                  <Button disabled={creatingGrade} className="btn btn-primary" style={{width:200}} color="primary">{"Crear"}</Button> 
              }
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
}

export default Grades;
