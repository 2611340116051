import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Col, Container, Form, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_ASSIST, API_GRADE, API_USER } from "../../../redux/apiRoutes";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import DatePicker from 'react-datepicker';
import es from "date-fns/locale/es";

import FilterComponent from "../../common/filter";
import SweetAlert from "sweetalert2";

const Assists = () => {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();
  
  const [modal, setModal] = useState(false);
  const toggle = () => {setModal(!modal);  reset(); setEditingAssist(false); clearModal();};

  const clearModal = () => {
    setStudentSelected([]);
    setGradeSelected([]);
    setdataStudents([]);
    setDate(new Date());
    setHomeworkAssignation('');
  }

  const [studentSelected,setStudentSelected] = useState([]);
  const [dataStudents,setdataStudents] = useState([]);
  const getStudentsList = async (grade) => {
    await axios.get(`${API_USER}?status=ACTIVE&role=STUDENT&grade=${grade[0].uuid}&checkAssist=true`).then(response => {
      if(response.data.docs.length>0){
        const students = [];
        response.data.docs.map(student=>{
          const addStudent = {
            uuid: student.uuid,
            name: student.names + ' ' + student.second_names,
            grade: student.grade,
            assist: student.assist,
          }
          students.push(addStudent);
        });
        setdataStudents(students);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Estudiantes: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const handleAssistChange = (value, studentUuid) => {
    const students = [];
    dataStudents.map(student=>{
      if(student.uuid===studentUuid){
        if(value){
          student.assist = true;
        }else{
          student.assist = false;
        }
      }
      students.push(student);
    });
    setdataStudents(students);
  }

  const handleGradeSelection = async (grade) => {
    setGradeSelected(grade);
    if(grade.length>0)
      getStudentsList(grade);
  }

  const [gradeSelected,setGradeSelected] = useState([]);
  const [dataGrades,setdataGrades] = useState([]);
  const getGradesList = async () => {
    await axios.get(`${API_GRADE}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        const grades = []
        response.data.docs.map(grade=>{
          const addGrade = {
            uuid: grade.uuid,
            name: grade.name + ' - ' + grade.level 
          }
          grades.push(addGrade);
        });
        setdataGrades(grades);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Grados Escolares: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [dataAssistList, setDataAssistList] = useState([]);
  const [isGetAssistList, setIsGetAssistList] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const getAssistList = async () => {
    setIsGetAssistList(true);

    let queryRule = '';
    if(user.role==='ADMIN' || user.role==='SISTEMAS' || user.role === "COORDINADOR_GENERAL"){
      setEditPermissions(true);
      queryRule = `status=TAKED`;
    }

    if(user.role === "COORDINADOR_PREESCOLAR"){
      setEditPermissions(true);
      queryRule = `status=TAKED&level=PREESCOLAR,MATERNAL`;
    }

    if(user.role === "COORDINADOR_PRIMARIA"){
      setEditPermissions(true);
      queryRule = `status=TAKED&level=PRIMARIA`;
    }

    if(user.role === "COORDINADOR_SECUNDARIA"){
      setEditPermissions(true);
      queryRule = `status=TAKED&level=SECUNDARIA`;
    }

    if(user.role === "COORDINADOR_PREPARATORIA"){
      setEditPermissions(true);
      queryRule = `status=TAKED&level=PREPARATORIA`;
    }

    if(user.role === "DOCENT"){
      //getTechGrades(user.uuid);
      setEditPermissions(true);
      queryRule = `status=TAKED&grade=PREPARATORIA`;
    }
    
    await axios.get(`${API_ASSIST}?${queryRule}`).then(response => {
      if(response.data.docs.length>0){
        setDataAssistList(response.data.docs);
      }else{
        setDataAssistList([]);
      }
      setIsGetAssistList(false);
    }).catch(e => {
      setIsGetAssistList(false);
      toast.error("No se pudo obtener el listado de Tareas: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const estado = (status) =>{
    switch(status){
      case 'TAKED':
        return <div><span className="badge badge-success">Tomada</span></div>;
      case 'DISABLED':
        return <div><span className="badge badge-warning">Desactivada</span></div>;
    }
  }

  const columns = [
    {
      name: "Fecha",
      selector: (row) => row.date.split('T')[0],
      sortable: true,
      center: true,
    },
    {
      name: "Salón",
      selector: (row) => row.grade,
      sortable: true,
      center: true,
    },
    {
      name: "Nivel",
      selector: (row) => row.level,
      sortable: true,
      center: true,
    },
    {
      name: "Estado",
      selector: (row) => estado(row.status),
      sortable: true,
      center: true,
    },
    {
      name: "Tomó Lista",
      selector: (row) => row.creatorName,
      sortable: true,
      center: true,
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleEditAction(row)}}><i className="fa fa-pencil"></i></Button>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleDeleteAction(row)}}><i className="fa fa-trash"></i></Button>
                        </div>,
      width: '160px'
    },
  ];

  const [homeworkAssignation, setHomeworkAssignation] = useState('');
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [date, setDate] = useState(new Date());

  const handleChangeStart = (date) => {
    setDate(date);
  };

  const [savingAssist, setSaveAssist] = useState(false);
  const saveAssist = async (data) => {
    setSaveAssist(true);
    if (data !== "") {
      if(gradeSelected.length<=0){
        toast.error("Indica el grupo al que tomarás lista", {position: 'bottom-right', style:{color:'white'}});
        setSaveAssist(false);
        return;
      }

      if(date==="" || date === null){
        toast.error("Ingresa la fecha de entrega", {position: 'bottom-right', style:{color:'white'}});
        setSaveAssist(false);
        return;
      }

      if(dataStudents.length===0){
        toast.error("No hay estudiantes para asignar a la lista", {position: 'bottom-right', style:{color:'white'}});
        setSaveAssist(false);
        return;
      }

      const dateFormat = date.toLocaleDateString().split("/");
      let dt = `${dateFormat[2]}-${dateFormat[0] < 10 ? `0${dateFormat[0]}` : dateFormat[0]}-${dateFormat[1]}`;
      data.date = dt;
      data.list = dataStudents;

      data.uuid = uuidv4();
      data.creatorUuid = user.uuid;
      data.gradeUuid = gradeSelected[0].uuid;
      data.level = gradeSelected[0].name.split(' - ')[1];
      data.grade = gradeSelected[0].name.split(' - ')[0];
      data.creatorName = user.name;
      data.status = 'TAKED';

      await axios.post(`${API_ASSIST}`,data).then(response => {
        if(response.data){
          toggle();
          getAssistList();
          reset();
          toast.success("¡Listado creado con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setSaveAssist(false);
      }).catch(e => {
        setSaveAssist(false);
        console.log(e)
        toast.error("No se pudo crear la lista de asistencia: " + e.request.response, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  };

  const [currentAssist, setCurrentAssist] = useState({});
  const handleEditAction = (assist) => {
    toggle();
    setEditingAssist(true);
    setCurrentAssist(assist);

    if(assist.gradeUuid!==undefined && assist.gradeUuid !== null){
      dataGrades.map((grade)=>{
        if(grade.uuid===assist.gradeUuid){
          setGradeSelected([grade]);
        }
      });
    }

    if(assist.list!==undefined && assist.list !== null){
      const students = []
      assist.list.map((student)=>{
        const addStudent = {
          uuid: student.uuid,
          name: student.name,
          grade: student.grade,
          assist: student.assist,
        }
        students.push(addStudent);
      });
      setdataStudents(students);
    }
    
    setTimeout(()=>{
      setDate(new Date(assist.date.split('T')[0]));
    },500)
  }

  const [isEditingAssist, setEditingAssist] = useState(false);
  const [patchingHomework, setPatchingHomework] = useState(false);
  const patchHomework = async (data) => {
    setPatchingHomework(true);

    if (data !== "") {
      if(gradeSelected.length<=0){
        toast.error("Indica el grupo al que tomarás lista", {position: 'bottom-right', style:{color:'white'}});
        setSaveAssist(false);
        return;
      }

      if(date==="" || date === null){
        toast.error("Ingresa la fecha de entrega", {position: 'bottom-right', style:{color:'white'}});
        setSaveAssist(false);
        return;
      }

      data.date = date;
      if(homeworkAssignation === 'GROUP'){ 
        data.gradeUuids = [gradeSelected[0].uuid];
        data.level = gradeSelected[0].name.split(' - ')[1];
        data.grade = gradeSelected[0].name.split(' - ')[0];
      }
  
      await axios.patch(`${API_ASSIST}/${currentAssist.uuid}`,data).then(response => {
        if(response.data){
          getAssistList();
          toggle();
          setPatchingHomework(false);
          toast.success("¡Tarea actualizar con éxito!", {position: 'bottom-right', style:{color:'white'}});
        }
        setPatchingHomework(false);
      }).catch(e => {
        setPatchingHomework(false);
        toast.error("No se pudo actualizar la tarea: " + e.message, {position: 'bottom-right', style:{color:'white'}});
      });
    } else {
      errors.showMessages();
    }
  }

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const handleDeleteAction = (data) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminará la lista de asistencia del ${data.date.split("T")[0]} para ${data.grade} ${data.level}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await axios.delete(`${API_ASSIST}/${data.uuid}`).then(response => {
          getAssistList();
          SweetAlert.fire("¡Listo!", "listado de asistencia eliminado", "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar el listado de asistencia: " + error
            );
          }, 200);
        });
      }
    });
  }

  const filteredItems = dataAssistList.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "COORDINADOR_GENERAL"
      && user.role !== "COORDINADOR_PREESCOLAR"
      && user.role !== "COORDINADOR_PRIMARIA"
      && user.role !== "COORDINADOR_SECUNDARIA"
      && user.role !== "COORDINADOR_PREPARATORIA"
      && user.role !== "DOCENT"
    ){
      navigate('/inicio');
    }
    getGradesList();
    getAssistList();
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Herramientas" title="Listas de Asistencias"/>
      {user.role !== 'TUTOR' ?
        <>
          <Container fluid={true}>
            <Row>
              <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
                <Button color="primary" onClick={toggle}>Tomar Nueva Lista</Button>
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <Card>
                  <CardBody className="data-tables">
                    <DataTable
                      columns={columns}
                      striped={true}
                      center={true}
                      data={filteredItems}
                      subHeader
                      pagination
                      subHeaderComponent={subHeaderComponent}
                      noDataComponent={<b style={{padding:10}}>No hay listas de asistencia</b>}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>

          <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>{isEditingAssist ? 'Editar Asistencia' : 'Nueva Lista de Asistencia' }
            <button className="btn-close invisible" type="button">
              <span aria-hidden="true" className="visible" onClick={toggle}></span>
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="container">
              <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingAssist ? patchHomework : saveAssist)} style={{padding:20}}>
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold">
                      Grado
                    </Label>
                    {isEditingAssist ?
                      <h4>{gradeSelected[0].name}</h4>
                    :
                    <Typeahead
                      id="gradeFinder"
                      clearButton
                      defaultSelected={gradeSelected}
                      labelKey="name"
                      options={dataGrades}
                      placeholder="Buscar Grupo de Alumnos..."
                      onChange={(e)=>{handleGradeSelection(e)}}
                    /> }
                  </Col>
                </Row>
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="validTitle">
                      Fecha Lista
                    </Label>
                    <DatePicker className="form-control digits"
                        selected={date}
                        onChange={handleChangeStart}
                        selectsStart
                        minDate={new Date()}
                        disabled={true}
                        locale={es}
                        dateFormat={'dd/MM/yyyy'}
                      />
                  </Col>
                </Row>
                {
                  dataStudents.length > 0 ?
                    <div className="container mt-3 p-3" style={{background: '#f4f4f4', borderRadius: 10}}>
                    <Row className="mb-2">
                      <Col sm="9">
                        <h6 className="font-weight-bold">Alumno(a)</h6>
                      </Col>
                      <Col sm="3">
                        <h6 className="font-weight-bold">Asistencia</h6>
                      </Col>
                    </Row>
                      {
                        dataStudents.map(student=>{
                          return(
                                <Row key={student.uuid}>
                                  <Col sm="9" className="mb-2">
                                    <h6>{student.name}</h6>
                                  </Col>
                                  <Col sm="3" className="text-center mb-2">
                                    <input size={30} type="checkbox" checked={student.assist} onChange={(e)=>handleAssistChange(e.target.checked,student.uuid)}/>
                                  </Col>
                                </Row>
                          )
                        })
                      }
                    </div>
                  : null
                }
                <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                {
                  isEditingAssist ? 
                    <Button disabled={patchingHomework} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                  : 
                    <Button disabled={savingAssist} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                }
                </Row>
              </Form>
            </div>
          </ModalBody>
        </Modal>

        </>
      : null }
    </Fragment>
  );
};

export default Assists;
