import { combineReducers } from "redux";
import Customizer from "./customizer/reducer";
import User from "./user/reducer";

const reducers = combineReducers({
  Customizer,
  User,
});

export default reducers;
