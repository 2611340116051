import React, { Fragment, useState } from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { Container, Col, Row, Card, CardHeader, CardBody, Table, Alert, Button, Label } from "reactstrap";
import DatePicker from 'react-datepicker';
import es from "date-fns/locale/es";

import { useSelector } from "react-redux";
import { useEffect } from "react";
import { API_ASSIST, API_GRADE } from "../../../redux/apiRoutes";
import axios from "axios";
import { toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";

const Home = () => {
  const user = useSelector((content) => content.User);

  // eslint-disable-next-line
  const [settings, setSettings] = useState({
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1548,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  const [startDate, setstartDate] = useState(new Date());
  const handleChangeStart = (date) => {
    setstartDate(date);
    getAssistData(date);
  };

  const [startDateGrade, setstartDateGrade] = useState(new Date());
  const handleChangeStartGrade = (date) => {
    setstartDateGrade(date);
  };

  const [endDateGrade, setendDateGrade] = useState(new Date());
  const handleChangeEndGrade = (date) => {
    setendDateGrade(date);
  };

  const [rooms, setrooms] = useState('N/A');
  const [lists, setlists] = useState('N/A');
  const [students, setstudents] = useState('N/A');
  const [assists, setassists] = useState('N/A');
  const [absences, setabsences] = useState('N/A');
  const [gettingAssistData, setGetAssistData] = useState(false);
  const getAssistData = async (date) => {
    console.log(date)
    setGetAssistData(true);
    
    await axios.get(`${API_ASSIST}/report?date=${date}`).then(response => {
      if(response.data){
        setrooms(response.data.rooms);
        setlists(response.data.lists);
        setstudents(response.data.students);
        setassists(response.data.assists);
        setabsences(response.data.absences);
      }
      setGetAssistData(false);
    }).catch(e => {
      setGetAssistData(false);
      toast.error("No se pudo obtener la información de asistencias: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const getCSV = async() => {
    await axios.get(`${API_ASSIST}/download/list?date=${startDate}`).then(response => {
      const blob = new Blob([response.data], { type: "data:text/csv;charset=utf-8," });
      const blobURL = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.download = `reporte_asistencia_${startDate.toISOString()}.csv`;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(
        ":"
      );
      anchor.click();
    }).catch(error => {
      setTimeout(() => {
        toast.error(
          "Ups, error al obtener el reporte de asistencia: " + error
        );
      }, 200);
    });
  }

  const getGradeCSV = async() => {
    await axios.get(`${API_ASSIST}/download/report?start_date=${startDateGrade}&end_date=${endDateGrade}&gradeUuid=${gradeSelected[0].uuid}`).then(response => {
      const blob = new Blob([response.data], { type: "data:text/csv;charset=utf-8," });
      const blobURL = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.download = `reporte_asistencia_${startDateGrade.toISOString()}_${endDateGrade.toISOString()}.csv`;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(
        ":"
      );
      anchor.click();
    }).catch(error => {
      setTimeout(() => {
        toast.error(
          "Ups, error al obtener el reporte de asistencia: " + error
        );
      }, 200);
    });
  }

  const [gradeSelected,setGradeSelected] = useState([]);
  const [dataGrades,setdataGrades] = useState([]);
  const getGradesList = async () => {
    await axios.get(`${API_GRADE}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        const grades = []
        response.data.docs.map(grade=>{
          const addGrade = {
            uuid: grade.uuid,
            name: grade.name + ' - ' + grade.level 
          }
          grades.push(addGrade);
        });
        setdataGrades(grades);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Grados Escolares: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  useEffect(() => {
    if(user.role !== "STUDENT" && user.role !== "TUTOR"){
      getAssistData(startDate);
      getGradesList();
    }
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Inicio" title="Bienvenid@" subtitle={user.name} />
      <Container fluid={true}>
        <Row>
          {/* <Col sm="11 box-col-12">
            <div className="crypto-slider">
              <Slider {...settings}>
                <div className="item">
                  <Card className="o-hidden">
                    <CardBody className="crypto-graph-card coin-card">
                      <div className="media">
                        <div className="media-body d-flex align-items-center">
                          <div className="rounded-icon bg-success">
                            <i className="ion ion-social-euro"></i>
                          </div>
                          <div className="bitcoin-graph-content">
                            <h5 className="f-w-700 mb-0">Litecoin </h5>
                          </div>
                        </div>
                        <div className="right-setting d-flex align-items-center">
                          <h6 className="font-success f-w-700 mb-0">
                            43.0465 <i className="f-20 ion ion-arrow-up-c ms-2"></i>
                          </h6>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="item">
                  <Card className="o-hidden">
                    <CardBody className="crypto-graph-card coin-card">
                      <div className="media">
                        <div className="media-body d-flex align-items-center">
                          <div className="rounded-icon bck-gradient-secondary">
                            <i className="ion ion-social-bitcoin"></i>
                          </div>
                          <div className="bitcoin-graph-content">
                            <h5 className="f-w-700 mb-0">Bitcoin</h5>
                          </div>
                        </div>
                        <div className="right-setting d-flex align-items-center">
                          <h6 className="font-secondary f-w-700 mb-0">
                            58.0225 <i className="f-20 ion ion-arrow-up-c ms-2"></i>
                          </h6>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="item">
                  <Card className="o-hidden">
                    <CardBody className="crypto-graph-card coin-card">
                      <div className="media">
                        <div className="media-body d-flex align-items-center">
                          <div className="rounded-icon bck-gradient-primary">
                            <i className="fa fa-try"></i>
                          </div>
                          <div className="bitcoin-graph-content">
                            <h5 className="f-w-700 mb-0">Ethereum</h5>
                          </div>
                        </div>
                        <div className="right-setting d-flex align-items-center">
                          <h6 className="font-primary f-w-700 mb-0">
                            60.0499 <i className="f-20 ion ion-arrow-up-c ms-2"></i>
                          </h6>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="item">
                  <Card className="o-hidden">
                    <CardBody className="crypto-graph-card coin-card">
                      <div className="media">
                        <div className="media-body d-flex align-items-center">
                          <div className="rounded-icon bck-gradient-secondary">
                            <i className="ion ion-social-bitcoin"></i>
                          </div>
                          <div className="bitcoin-graph-content">
                            <h5 className="f-w-700 mb-0">Bitcoin</h5>
                          </div>
                        </div>
                        <div className="right-setting d-flex align-items-center">
                          <h6 className="font-secondary f-w-700 mb-0">
                            58.0225 <i className="f-20 ion ion-arrow-up-c ms-2"></i>
                          </h6>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Slider>
            </div>
          </Col>
          <Col sm="1 Add-card">
            <div className="add-arrow">
              <div className="more-btn text-center">
                <button className="btn btn-secondary d-block w-100 f-w-700">Add more </button>
                <button className="btn btn-light">
                  <i className="ion ion-plus font-secondary f-22"></i>
                </button>
              </div>
            </div>
          </Col> */}
          {user.role !== "STUDENT" && user.role !== "TUTOR" ?
            <>
              <Col xl="4 xl-50 box-col-6">
                <Card>
                  <CardHeader className="no-border">
                    <h5>Listas de Asistencias por día</h5>
                    <ul className="creative-dots">
                      <li className="bg-primary big-dot"></li>
                      <li className="bg-secondary semi-big-dot"></li>
                      <li className="bg-warning medium-dot"></li>
                      <li className="bg-info semi-medium-dot"></li>
                      <li className="bg-secondary semi-small-dot"></li>
                      <li className="bg-primary small-dot"></li>
                    </ul>
                  </CardHeader>
                  <CardBody className="px-0 pt-0">
                    <div style={{position:'absolute', top:50, right:30}}>
                      <DatePicker className="form-control digits"
                        selected={startDate}
                        onChange={handleChangeStart}
                        maxDate={new Date()}
                        locale={es}
                        dateFormat={'dd/MM/yyyy'}
                      />
                    </div>
                    {gettingAssistData ? 
                      'Cargando...' 
                    :
                    <>
                      <div className="crypto-table-market table-responsive row mt-4">
                        <div className="col-md-4" style={{textAlign: 'center'}}>
                          <Alert color="light text-secondary">
                            <h5 className="mb-0">Salones: {rooms}</h5>
                          </Alert>
                        </div>
                        <div className="col-md-4" style={{textAlign: 'center'}}>
                          <Alert color="light text-secondary">
                            <h5 className="mb-0">Listas: {lists}</h5>
                          </Alert>
                        </div>
                        <div className="col-md-4" style={{textAlign: 'center'}}>
                          <Alert color="light text-secondary">
                            <h5 className="mb-0">Alumnos: {students}</h5>
                          </Alert>
                        </div>
                      </div>
                      <div className="crypto-table-market table-responsive row mt-3">
                        <div className="col-md-4" style={{textAlign: 'center'}}>
                          <Alert color="light text-success">
                            <h5 className="mb-0">Asistencias: {assists}</h5>
                          </Alert>
                        </div>
                        <div className="col-md-4" style={{textAlign: 'center'}}>
                          <Alert color="light text-danger">
                            <h5 className="mb-0">Ausencias: {absences}</h5>
                          </Alert>
                        </div>
                        <div className="col-md-12 mt-4 mb-0" style={{textAlign:'right'}}>
                          {lists>0 ?
                          <Button onClick={getCSV} className="primary">Descargar CSV</Button> : ''}
                        </div>
                      </div>
                    </>
                    }
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4 xl-50 box-col-6">
                <Card>
                  <CardHeader className="no-border">
                    <h5>Asistencias por salón y fechas</h5>
                    <ul className="creative-dots">
                      <li className="bg-primary big-dot"></li>
                      <li className="bg-secondary semi-big-dot"></li>
                      <li className="bg-warning medium-dot"></li>
                      <li className="bg-info semi-medium-dot"></li>
                      <li className="bg-secondary semi-small-dot"></li>
                      <li className="bg-primary small-dot"></li>
                    </ul>
                  </CardHeader>
                  <CardBody className="p-3">
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold">
                          Grado
                        </Label>
                        <Typeahead
                          id="gradeFinder"
                          clearButton
                          defaultSelected={gradeSelected}
                          labelKey="name"
                          options={dataGrades}
                          placeholder="Buscar Grupo de Alumnos..."
                          onChange={(e)=>{setGradeSelected(e)}}
                        />
                      </Col>
                    </Row>
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold" for="validTitle">
                          Fecha Inicio
                        </Label>
                          <DatePicker className="form-control digits"
                            selected={startDateGrade}
                            onChange={handleChangeStartGrade}
                            maxDate={new Date()}
                            locale={es}
                            dateFormat={'dd/MM/yyyy'}
                          />
                      </Col>
                    </Row>
                    <Row className="g-3 dflex mb-3">
                      <Col md="12">
                        <Label className="form-label font-weight-bold" for="validTitle">
                          Fecha Fin
                        </Label>
                          <DatePicker className="form-control digits"
                            selected={endDateGrade}
                            onChange={handleChangeEndGrade}
                            maxDate={new Date()}
                            locale={es}
                            dateFormat={'dd/MM/yyyy'}
                          />
                      </Col>
                    </Row>
                    <div className="col-md-12 mt-4 mb-0" style={{textAlign:'right'}}>
                      <Button onClick={getGradeCSV} className="primary">Descargar</Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </>
          : 
            <>
            <div style={{display:'flex', justifyContent:'center'}}>
              <img src={require('../../../assets/images/childs.png')} style={{maxWidth:250, marginTop:50}}/>
            </div>
              <h4 style={{textAlign:'center'}} className="text-secondary mt-4">Estamos siempre mejorando para ti, proximamente <br/>agregaremos más funciones a tu App del Instituto Nelson Mandela.</h4>
            </>
          }
          {/* <Col xl="4 xl-50 box-col-6">
            <Card>
              <CardHeader className="no-border">
                <h5>Asignación de Tareas</h5>
                <ul className="creative-dots">
                  <li className="bg-primary big-dot"></li>
                  <li className="bg-secondary semi-big-dot"></li>
                  <li className="bg-warning medium-dot"></li>
                  <li className="bg-info semi-medium-dot"></li>
                  <li className="bg-secondary semi-small-dot"></li>
                  <li className="bg-primary small-dot"></li>
                </ul>
              </CardHeader>
              <CardBody className="px-0 pt-0">
                <div className="setting-dot">
                  <div className="setting-bg-primary bg-white position-set pull-right">
                    <i className="icofont icofont-gear fa fa-spin fa-cog"></i>
                  </div>
                </div>
                <div className="crypto-table-market table-responsive">
                  <Table borderless>
                    <tbody>
                      <tr>
                        <td className="font-primary f-w-700 pt-0">Bitcoin</td>
                        <td className="pt-0">
                          <span className="f-w-700">$ 50</span>
                        </td>
                        <td className="pt-0">
                          <span>4 Jan</span>
                        </td>
                        <td className="pt-0">
                          <span className="badge rounded-pill f-14 font-primary">+ 2.6%</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="font-primary f-w-700">Ethereum</td>
                        <td>
                          <span className="f-w-700">$ 56</span>
                        </td>
                        <td>
                          <span>18 Feb</span>
                        </td>
                        <td>
                          <span className="badge rounded-pill f-14 font-primary">- 3.7%</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="font-secondary f-w-700">Ripple</td>
                        <td>
                          <span className="f-w-700">$ 80</span>
                        </td>
                        <td>
                          <span>26 March</span>
                        </td>
                        <td>
                          <span className="badge rounded-pill f-14 font-secondary">+ 8.3%</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="font-success f-w-700">EOS</td>
                        <td>
                          <span className="f-w-700">$ 47</span>
                        </td>
                        <td>
                          <span>2 May</span>
                        </td>
                        <td>
                          <span className="badge rounded-pill f-14 font-success">- 2.6%</span>
                        </td>
                      </tr>
                      <tr>
                        <td className="font-primary f-w-700 pb-0">Litecoin</td>
                        <td className="pb-0">
                          <span className="f-w-700">$ 47</span>
                        </td>
                        <td className="pb-0">
                          <span>10 Jun</span>
                        </td>
                        <td className="pb-0">
                          <span className="badge rounded-pill f-14 font-primary">+ 5.6%</span>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </Fragment>
  );
};

export default Home;
