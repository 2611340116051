import { START_SESSION } from "../actionType";
import axios from "axios";

export const loginUser = (user) => async (dispatch) => {
  await new Promise((resolve, reject) => {
    dispatch({
        type: START_SESSION,
        user: user,
    });
  })
};
