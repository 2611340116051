import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { Edit, Video, Image, Activity, Clock, User, Folder } from "react-feather";
import { Button, Card, CardBody, Col, Container, Form, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { API_ABSENCE, API_GRADE, API_HOMEWORK, API_USER } from "../../../redux/apiRoutes";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import DatePicker from 'react-datepicker';
import es from "date-fns/locale/es";

import Compressor from 'compressorjs';
import AWS from 'aws-sdk';
import FilterComponent from "../../common/filter";
import SweetAlert from "sweetalert2";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_REGION;
const AWS_URL_BUCKET = process.env.REACT_APP_AWS_URL_BUCKET;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET},
  region: REGION,
})

const HomeWork = () => {
  const user = useSelector((content) => content.User);
  const navigate = useNavigate();
  
  const [modal, setModal] = useState(false);
  const toggle = () => {setModal(!modal);  reset(); setEditinHomework(false); clearModal();};

  const clearModal = () => {
    setStudentSelected([]);
    setGradeSelected([]);
    setDate(new Date());
    setHomeworkAssignation('');
    setFileImage('')
  }

  const [childSelected,setChildSelected] = useState([]);
  const [dataChilds,setdataChilds] = useState([]);
  const getChildsList = async () => {
    await axios.get(`${API_USER}?status=ACTIVE&uuid=${user.uuid}`).then(response => {
      if(response.data.docs.length>0){
        if(response.data.docs[0].childs){
          const childs = []
          response.data.docs[0].childs.map(child=>{
            const addChild = {
              uuid: child.uuid,
              name: child.name,
              grade: child.grade,
            }
            childs.push(addChild);
          });
          setdataChilds(childs);
        }
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Estudiantes: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [studentSelected,setStudentSelected] = useState([]);
  const [dataStudents,setdataStudents] = useState([]);
  const getStudentsList = async () => {
    await axios.get(`${API_USER}?status=ACTIVE&role=STUDENT`).then(response => {
      if(response.data.docs.length>0){
        const students = []
        response.data.docs.map(student=>{
          const addStudent = {
            uuid: student.uuid,
            name: student.names + ' ' + student.second_names,
            grade: student.grade,
          }
          students.push(addStudent);
        });
        setdataStudents(students);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Estudiantes: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [gradeSelected,setGradeSelected] = useState([]);
  const [dataGrades,setdataGrades] = useState([]);
  const getGradesList = async () => {
    await axios.get(`${API_GRADE}?status=ACTIVE`).then(response => {
      if(response.data.docs.length>0){
        const grades = []
        response.data.docs.map(grade=>{
          const addGrade = {
            uuid: grade.uuid,
            name: grade.name + ' - ' + grade.level 
          }
          grades.push(addGrade);
        });
        setdataGrades(grades);
      }
    }).catch(e => {
      toast.error("No se pudo obtener el listado de Grados Escolares: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const [existHomeWork, setexistHomeWork] = useState(false);
  const [timeline, setTimeline] = useState([]);
  const [loadingTimeline, setLoadingTimeline] = useState(false);
  const getHomeworkByStudent = async (student) => {
    setChildSelected(student);
    setLoadingTimeline(true);
    if(student.length>0){
      setexistHomeWork(true);
      await axios.get(`${API_HOMEWORK}?status=ACTIVE&userUuids=${student[0].uuid}&gradeUuids=${student[0].grade[0].uuid}&limit=20`).then(response => {
        if(response.data.docs.length>0){
          setTimeline(response.data.docs);
        }else{
          setTimeline([]);
        }
        setLoadingTimeline(false);
      }).catch(e => {
        toast.error("No se pudo obtener el listado de Grados Escolares: " + e.message, {position: 'bottom-right', style:{color:'white'}});
        setLoadingTimeline(false);
      });
    }else{
      setexistHomeWork(false);
    }
  }

  const [dataHomeworkList, setDataHomeworkList] = useState([]);
  const [isGetHomeworkList, setIsGetHomeWorkList] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const getHomeworkList = async () => {
    setIsGetHomeWorkList(true);

    let queryRule = '';
    if(user.role==='ADMIN' || user.role==='SISTEMAS' || user.role === "COORDINADOR_GENERAL"){
      setEditPermissions(true);
      queryRule = `status=ACTIVE`;
    }

    if(user.role === "COORDINADOR_PREESCOLAR"){
      setEditPermissions(true);
      queryRule = `status=ACTIVE&level=PREESCOLAR,MATERNAL`;
    }

    if(user.role === "COORDINADOR_PRIMARIA"){
      setEditPermissions(true);
      queryRule = `status=ACTIVE&level=PRIMARIA`;
    }

    if(user.role === "COORDINADOR_SECUNDARIA"){
      setEditPermissions(true);
      queryRule = `status=ACTIVE&level=SECUNDARIA`;
    }

    if(user.role === "COORDINADOR_PREPARATORIA"){
      setEditPermissions(true);
      queryRule = `status=ACTIVE&level=PREPARATORIA`;
    }

    if(user.role === "DOCENT"){
      //getTechGrades(user.uuid);
      setEditPermissions(true);
      queryRule = `status=ACTIVE`;
    }
    
    await axios.get(`${API_HOMEWORK}?${queryRule}`).then(response => {
      if(response.data.docs.length>0){
        setDataHomeworkList(response.data.docs);
      }else{
        setDataHomeworkList([]);
      }
      setIsGetHomeWorkList(false);
    }).catch(e => {
      setIsGetHomeWorkList(false);
      toast.error("No se pudo obtener el listado de Tareas: " + e.message, {position: 'bottom-right', style:{color:'white'}});
    });
  }

  const estado = (status) =>{
    switch(status){
      case 'ACTIVE':
        return <div><span className="badge badge-success">Activa</span></div>;
      case 'DISABLED':
        return <div><span className="badge badge-warning">Desactivada</span></div>;
    }
  }

  const tipo = (type) =>{
    switch(type){
      case 'SELF':
        return <span className="text-primary">Individual</span>;
      case 'GROUP':
        return <span className="text-danger">Grupal</span>;
    }
  }

  const columns = [
    {
      name: "Titulo",
      selector: (row) => <b>{row.title}</b>,
      sortable: true,
      center: true,
      width: '150px'
    },
    {
      name: "Tipo",
      selector: (row) => <b>{tipo(row.type)}</b>,
      sortable: true,
      center: true,
      width: '100px'
    },
    {
      name: "Alumno(a)",
      selector: (row) => <b>{row.type === 'GROUP' ? 'N/A' : row.studentName}</b>,
      sortable: true,
      center: true,
      width: '150px'
    },
    {
      name: "Salón y Nivel",
      selector: (row) => row.grade + '-' +row.level,
      sortable: true,
      center: true,
      width: '180px'
    },
    {
      name: "Estado",
      selector: (row) => estado(row.status),
      sortable: true,
      center: true,
    },
    {
      name: "Fecha Entrega",
      selector: (row) => row.date ? new Date(row.date).getDate() + '-' + (new Date(row.date).getMonth() + 1) + '-' + new Date(row.date).getFullYear() : '--',
      sortable: true,
      center: true,
    },
    {
      name: "Publicó",
      selector: (row) => row.creatorName,
      sortable: true,
      center: true,
    },
    {
      name: "Acciones",
      selector: (row) => <div style={{padding:5}}>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleEditAction(row)}}><i className="fa fa-pencil"></i></Button>
                          <Button color="light" style={{width: 40, padding: 6, marginRight:5, fontSize: 14}} onClick={()=>{handleDeleteAction(row)}}><i className="fa fa-trash"></i></Button>
                        </div>,
      width: '160px'
    },
  ];

  const [homeworkAssignation, setHomeworkAssignation] = useState('');
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [fileImage, setFileImage] = useState('');
  const [date, setDate] = useState(new Date());

  const handleChangeStart = (date) => {
    setDate(date);
  };

  const [savingHomework, setSaveHomework] = useState(false);
  const saveHomework = async (data) => {
    setSaveHomework(true);
    if (data !== "") {
      if(homeworkAssignation === ''){
        toast.error("Indica si la tarea es para un alumno(a) o grupo escolar", {position: 'bottom-right', style:{color:'white'}});
        setSaveHomework(false);
        return;
      }

      if(homeworkAssignation === 'SELF' && studentSelected.length<=0){
        toast.error("Indica el alumno(a) al que asignarás la tarea", {position: 'bottom-right', style:{color:'white'}});
        setSaveHomework(false);
        return;
      }

      if(homeworkAssignation === 'GROUP' && gradeSelected.length<=0){
        toast.error("Indica el grupo al que asignarás la tarea", {position: 'bottom-right', style:{color:'white'}});
        setSaveHomework(false);
        return;
      }

      if(date==="" || date === null){
        toast.error("Ingresa la fecha de entrega", {position: 'bottom-right', style:{color:'white'}});
        setSaveHomework(false);
        return;
      }
      data.date = date;

      data.uuid = uuidv4();
      data.creatorUuid = user.uuid;
      data.type = homeworkAssignation;
      if(homeworkAssignation === 'GROUP'){ 
        data.gradeUuids = [gradeSelected[0].uuid];
        data.level = gradeSelected[0].name.split(' - ')[1];
        data.grade = gradeSelected[0].name.split(' - ')[0];
      }
      if(homeworkAssignation === 'SELF'){ 
        data.userUuids = [studentSelected[0].uuid];
        data.level = studentSelected[0].grade[0].name.split(' - ')[1];
        data.grade = studentSelected[0].grade[0].name.split(' - ')[0];
      }
      data.status = 'ACTIVE';

      if(fileImage!==''){
        let file = fileImage;

        new Compressor(file, {
          quality: 0.8,
          success: async (compressedResult) => {   
            const compressed = compressedResult;
            
            const fileName = data.uuid + '_picture_' + file.name;
            const params = {
                ACL: 'public-read',
                Body: compressed,
                Key: `homework-documents/${fileName}`,
                Bucket: S3_BUCKET,
            };
            myBucket.putObject(params)
              .on('httpUploadProgress', (evt) => {
                console.log(Math.round((evt.loaded / evt.total) * 100));
              })
              .send(async (err) => {
                  if (err) {
                    toast.error("Error al guardar la imagen de la tarea: "+err, {position: 'bottom-right', style:{color:'white'}});
                  }else{
                    data.pictures = [`${AWS_URL_BUCKET}/homework-documents/${fileName}`];
                    if(data.urls !== ''){data.urls = [data.urls] }
                    if(data.videos !== ''){data.videos = [data.videos] }
              
                    await axios.post(`${API_HOMEWORK}`,data).then(response => {
                      if(response.data){
                        toggle();
                        getHomeworkList();
                        reset();
                        toast.success("¡Tarea creada con éxito!", {position: 'bottom-right', style:{color:'white'}});
                      }
                      setSaveHomework(false);
                    }).catch(e => {
                      setSaveHomework(false);
                      toast.error("No se pudo crear la tarea: " + e.message, {position: 'bottom-right', style:{color:'white'}});
                    });
                  }
              })
          },
        });
      }else{
        if(data.urls !== ''){data.urls = [data.urls] }
        if(data.videos !== ''){data.videos = [data.videos] }
  
        await axios.post(`${API_HOMEWORK}`,data).then(response => {
          if(response.data){
            toggle();
            getHomeworkList();
            reset();
            toast.success("¡Tarea creada con éxito!", {position: 'bottom-right', style:{color:'white'}});
          }
          setSaveHomework(false);
        }).catch(e => {
          setSaveHomework(false);
          toast.error("No se pudo crear la tarea: " + e.message, {position: 'bottom-right', style:{color:'white'}});
        });
      }
    } else {
      errors.showMessages();
    }
  };

  const [currentHomeWork, setCurrentHomework] = useState({});
  const handleEditAction = (homework) => {
    toggle();
    setEditinHomework(true);
    setCurrentHomework(homework);
    
    if(homework.userUuids!==undefined && homework.userUuids !== null){
      dataStudents.map((student)=>{
        if(student.uuid===homework.userUuids[0]){
          setStudentSelected([student]);
        }
      });
    }

    if(homework.gradeUuids!==undefined && homework.gradeUuids !== null){
      dataGrades.map((grade)=>{
        if(grade.uuid===homework.gradeUuids[0]){
          setGradeSelected([grade]);
        }
      });
    }

    setHomeworkAssignation(homework.type);
    
    setTimeout(()=>{
      setDate(new Date(homework.date.split('T')[0]));
      setValue('title', homework.title);
      setValue('description', homework.description);
      if(homework.urls!==''){
        setValue('urls', homework.urls[0]);
      }else{
        setValue('urls', homework.urls);
      }
      if(homework.videos!==''){
        setValue('videos', homework.videos[0]);
      }else{
        setValue('videos', homework.videos);
      }
    },500)
  }

  const [removingPicture, setRemovingPicture] = useState(false);

  const handleRemovePicture = async () => {

  }

  const [isEditingHomework, setEditinHomework] = useState(false);
  const [patchingHomework, setPatchingHomework] = useState(false);
  const patchHomework = async (data) => {
    setPatchingHomework(true);

    if (data !== "") {
      if(homeworkAssignation === ''){
        toast.error("Indica si la tarea es para un alumno(a) o grupo escolar", {position: 'bottom-right', style:{color:'white'}});
        setSaveHomework(false);
        return;
      }

      if(homeworkAssignation === 'SELF' && studentSelected.length<=0){
        toast.error("Indica el alumno(a) al que asignarás la tarea", {position: 'bottom-right', style:{color:'white'}});
        setSaveHomework(false);
        return;
      }

      if(homeworkAssignation === 'GROUP' && gradeSelected.length<=0){
        toast.error("Indica el grupo al que asignarás la tarea", {position: 'bottom-right', style:{color:'white'}});
        setSaveHomework(false);
        return;
      }

      if(date==="" || date === null){
        toast.error("Ingresa la fecha de entrega", {position: 'bottom-right', style:{color:'white'}});
        setSaveHomework(false);
        return;
      }

      data.date = date;
      data.type = homeworkAssignation;
      if(homeworkAssignation === 'GROUP'){ 
        data.gradeUuids = [gradeSelected[0].uuid];
        data.level = gradeSelected[0].name.split(' - ')[1];
        data.grade = gradeSelected[0].name.split(' - ')[0];
      }
      if(homeworkAssignation === 'SELF'){ 
        data.userUuids = [studentSelected[0].uuid];
        data.level = studentSelected[0].grade[0].name.split(' - ')[1];
        data.grade = studentSelected[0].grade[0].name.split(' - ')[0];
      }

      if(fileImage!==''){
        let file = fileImage;

        new Compressor(file, {
          quality: 0.8,
          success: async (compressedResult) => {   
            const compressed = compressedResult;
            
            const fileName = data.uuid + '_picture_' + file.name;
            const params = {
                ACL: 'public-read',
                Body: compressed,
                Key: `homework-documents/${fileName}`,
                Bucket: S3_BUCKET,
            };
            myBucket.putObject(params)
              .on('httpUploadProgress', (evt) => {
                console.log(Math.round((evt.loaded / evt.total) * 100));
              })
              .send(async (err) => {
                  if (err) {
                    toast.error("Error al guardar la imagen de la tarea: "+err, {position: 'bottom-right', style:{color:'white'}});
                  }else{
                    data.pictures = [`${AWS_URL_BUCKET}/homework-documents/${fileName}`];
                    if(data.urls !== ''){data.urls = [data.urls] }
                    if(data.videos !== ''){data.videos = [data.videos] }
              
                    await axios.patch(`${API_HOMEWORK}/${currentHomeWork.uuid}`,data).then(response => {
                      if(response.data){
                        getHomeworkList();
                        toggle();
                        setPatchingHomework(false);
                        toast.success("¡Tarea actualizada con éxito!", {position: 'bottom-right', style:{color:'white'}});
                      }
                      setPatchingHomework(false);
                    }).catch(e => {
                      setPatchingHomework(false);
                      toast.error("No se pudo actualizar la tarea: " + e.message, {position: 'bottom-right', style:{color:'white'}});
                    });
                  }
              })
          },
        });
      }else{
        if(data.urls !== ''){data.urls = [data.urls] }
        if(data.videos !== ''){data.videos = [data.videos] }
  
        await axios.patch(`${API_HOMEWORK}/${currentHomeWork.uuid}`,data).then(response => {
          if(response.data){
            getHomeworkList();
            toggle();
            setPatchingHomework(false);
            toast.success("¡Tarea actualizar con éxito!", {position: 'bottom-right', style:{color:'white'}});
          }
          setPatchingHomework(false);
        }).catch(e => {
          setPatchingHomework(false);
          toast.error("No se pudo actualizar la tarea: " + e.message, {position: 'bottom-right', style:{color:'white'}});
        });
      }
    } else {
      errors.showMessages();
    }
  }

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(
    false
  );

  const handleDeleteAction = (data) => {
    SweetAlert.fire({
      title: "¿Continuamos?",
      text: `Se eliminará la tarea: ${data.title} (para todos los involucrados)`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        if(data.pictures && data.pictures.length>0){
          const deleteParams = {
            Bucket: S3_BUCKET,
            Key: `homework-documents/${data.pictures[0].split('/homework-documents/')[1]}`
          }
          myBucket.deleteObject(deleteParams).send();
        }

        await axios.delete(`${API_HOMEWORK}/${data.uuid}`).then(response => {
          getHomeworkList();
          SweetAlert.fire("¡Listo!", "tarea eliminada", "success");
        }).catch(error => {
          setTimeout(() => {
            toast.error(
              "Error al eliminar la tarea: " + error
            );
          }, 200);
        });
      }
    });
  }

  const filteredItems = dataHomeworkList.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    if(
      user.role !== 'ADMIN' 
      && user.role !== "SISTEMAS"
      && user.role !== "TUTOR"
      && user.role !== "COORDINADOR_GENERAL"
      && user.role !== "COORDINADOR_PREESCOLAR"
      && user.role !== "COORDINADOR_PRIMARIA"
      && user.role !== "COORDINADOR_SECUNDARIA"
      && user.role !== "COORDINADOR_PREPARATORIA"
      && user.role !== "DOCENT"
    ){
      navigate('/inicio');
    }
    if(user.role!=='TUTOR'){
      getStudentsList();
      getGradesList();
      getHomeworkList();
    }else{
      getChildsList();
    }
  }, [navigate]);

  return (
    <Fragment>
      <Breadcrumb parent="Herramientas" title="Tareas"/>
      {user.role === 'TUTOR' ?
        <>
          <Container style={{background:'white', margin:20, borderRadius: 10}}>
            <Row className="g-3 dflex mb-3 p-3 pb-4">
              <Col md="12">
                <Typeahead
                  id="childFinder"
                  clearButton
                  defaultSelected={childSelected}
                  labelKey="name"
                  options={dataChilds}
                  placeholder="Buscar Alumno(a)..."
                  onChange={(e)=>{getHomeworkByStudent(e)}}
                />
              </Col>
            </Row>
          </Container>
          <Container fluid={true}>
            { existHomeWork ?
            <>
            <div className="mb-5"></div>
              { 
                !loadingTimeline ?
                  timeline.length > 0 ?
                  <VerticalTimeline>
                    {timeline.map(time=>{
                      let icon = <Edit />;
                      if(time.pictures && time.pictures.length>0){
                        icon = <Image />
                      }
                      if(time.videos && time.videos.length>0){
                        icon = <Video />
                      }
                      const date = new Date(time.createdAt.split('T')[0]);
                      return(
                        <>
                          <VerticalTimelineElement
                            key={time.uuid}
                            className="vertical-timeline-element--work"
                            animate={true}
                            date={date.toLocaleDateString()}
                            icon={icon}
                          >
                            <h4 className="vertical-timeline-element-subtitle mb-2">
                            {time.title}
                            </h4>
                            <h7>
                              <b><Clock size={15} /> Entregar:</b> {time.date ? new Date(time.date).getDate() + '-' + (new Date(time.date).getMonth() + 1) + '-' + new Date(time.date).getFullYear() : '--'}
                              <br/>
                              <b><Folder size={15} /> Tipo: {tipo(time.type)}</b>
                              <br/>
                              <b><User size={15} /> Publicado por: {time.creatorName}</b>
                            </h7>
                            <h6 style={{marginTop:20}}>{time.description}</h6>
                            {time.urls && time.urls.length>0 ?
                              <p>Enlaces: <a href={time.urls[0]} target="_blank">{time.urls[0]}</a></p>
                            : null}
                            {time.pictures && time.pictures.length>0 ?
                            <a href={time.pictures[0]} target="_blank">
                            <img
                              className="img-fluid p-t-20 w-100"
                              src={time.pictures[0]}
                              alt="timelineImg"
                            /></a>: null}
                            {time.videos && time.videos.length>0 ?
                            <div className="embed-responsive embed-responsive-21by9 ratio ratio-21x9 m-t-20">
                              <iframe
                                src={time.videos[0]}
                                allowFullScreen
                                title="videotimeline"
                              ></iframe>
                            </div>: null}
                          </VerticalTimelineElement>
                        </>
                      )
                    })}
                  </VerticalTimeline>
                  : <h3 className="text-secondary" style={{textAlign:'center'}}>Sin tareas asignadas</h3>
                : <h3 className="text-secondary" style={{textAlign:'center'}}>Cargando...</h3> }
            </>
            : null}
          </Container>
        </>
      : null}

      {user.role !== 'TUTOR' ?
        <>
          <Container fluid={true}>
            <Row>
              <Col sm="12" style={{display:'flex', justifyContent:'flex-end', marginBottom: 20, paddingRight:30}}>
                <Button color="primary" onClick={toggle}>Crear Tarea</Button>
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <Card>
                  <CardBody className="data-tables">
                    <DataTable
                      columns={columns}
                      striped={true}
                      center={true}
                      data={filteredItems}
                      subHeader
                      pagination
                      subHeaderComponent={subHeaderComponent}
                      noDataComponent={<b style={{padding:10}}>No hay tareas creadas</b>}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>

          <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>{isEditingHomework ? 'Editar Tarea' : 'Crear Nueva Tarea' }
            <button className="btn-close invisible" type="button">
              <span aria-hidden="true" className="visible" onClick={toggle}></span>
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="container">
              <Row className="g-3 dflex">
                <Col md="12">
                  <select className="form-control mb-1" defaultValue={homeworkAssignation} onChange={(e)=>setHomeworkAssignation(e.target.value)}>
                    <option value="">-- Elige el tipo de tarea --</option>
                    <option value="SELF">Para un alumno(a) en especifico</option>
                    <option value="GROUP">Para un grupo en general</option>
                  </select>
                </Col>
              </Row>
              <Form className="needs-validation" noValidate="" onSubmit={handleSubmit( isEditingHomework ? patchHomework : saveHomework)} style={{padding:20}}>
                {homeworkAssignation === 'SELF' ?
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold">
                      Alumno(a)
                    </Label>
                    <Typeahead
                      id="gradeFinder"
                      clearButton
                      defaultSelected={studentSelected}
                      labelKey="name"
                      options={dataStudents}
                      placeholder="Buscar Alumno(a)..."
                      onChange={(e)=>{setStudentSelected(e)}}
                    />
                  </Col>
                </Row> : null}
                {homeworkAssignation === 'GROUP' ?
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold">
                      Grado
                    </Label>
                    <Typeahead
                      id="gradeFinder"
                      clearButton
                      defaultSelected={gradeSelected}
                      labelKey="name"
                      options={dataGrades}
                      placeholder="Buscar Grupo de Alumnos..."
                      onChange={(e)=>{setGradeSelected(e)}}
                    />
                  </Col>
                </Row> : null }
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="validTitle">
                      Fecha Entrega
                    </Label>
                    <DatePicker className="form-control digits"
                        selected={date}
                        onChange={handleChangeStart}
                        selectsStart
                        minDate={new Date()}
                        locale={es}
                        dateFormat={'dd/MM/yyyy'}
                      />
                  </Col>
                </Row>
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="validTitle">
                      Titulo
                    </Label>
                    <input className="form-control" id="validTitle" type="text" placeholder="Titulo" name="title" {...register("title", { required: true })} />
                    <span>{errors.title && "Titulo requerido"}</span>
                  </Col>
                </Row>
                <Row className="g-3 dflex mb-3">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="validDetails">
                      Detalles
                    </Label>
                    <textarea style={{minHeight:150}} className="form-control" id="validDetails" type="text" placeholder="Descripción de la tarea" name="description" {...register("description")} />
                  </Col>
                </Row>
                <Row className="g-3 dflex mb-4">
                  <Col md="12">
                    <Label className="form-label font-weight-bold">
                      Imagen
                    </Label>&nbsp;
                    {isEditingHomework ?
                      <>
                        {currentHomeWork.pictures !== null && currentHomeWork.pictures !== undefined ?
                        <div>
                          <img src={`${currentHomeWork.pictures[0]}`} style={{maxWidth:300}} />
                          <p>{!removingPicture ? <span onClick={handleRemovePicture} style={{color:'red', cursor:'pointer'}}>Quitar</span> : null}</p>
                        </div>
                        : 
                        <input type="file" className="form-control" accept="image/*" onChange={(e)=>{if (e.target.files[0].size > 1000000) {
                          toast.error("El archivo no puede pesar más de 1MB",{position: 'bottom-right', style:{color:'white'}});
                          e.target.files = null;
                          e.target.value = null;
                          return;
                        }setFileImage(e.target.files[0])}}/>
                        }
                      </>
                    :
                    <input type="file" className="form-control" accept="image/*" onChange={(e)=>{if (e.target.files[0].size > 1000000) {
                      toast.error("El archivo no puede pesar más de 1MB",{position: 'bottom-right', style:{color:'white'}});
                      e.target.files = null;
                      e.target.value = null;
                      return;
                    }setFileImage(e.target.files[0])}}/>}
                  </Col>
                </Row>
                <Row className="g-3 dflex mb-4">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="validUrl">
                      Enlace Web
                    </Label>
                    <input className="form-control" id="validUrl" type="text" placeholder="Enlace Web https://" name="urls" {...register("urls")} />
                  </Col>
                </Row>
                <Row className="g-3 dflex mb-4">
                  <Col md="12">
                    <Label className="form-label font-weight-bold" for="validYoutube">
                      Video Youtube
                    </Label>
                    <input className="form-control" id="validYoutube" type="text" placeholder="Video Youtube Embeded https://" name="videos" {...register("videos")} />
                  </Col>
                </Row>
                <Row style={{display:'flex', justifyContent:'end', paddingTop:20}}>
                {
                  isEditingHomework ? 
                    <Button disabled={patchingHomework} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                  : 
                    <Button disabled={savingHomework} className="btn btn-primary" style={{width:150}} color="primary">{"Guardar"}</Button> 
                }
                </Row>
              </Form>
            </div>
          </ModalBody>
        </Modal>

        </>
      : null }
    </Fragment>
  );
};

export default HomeWork;
