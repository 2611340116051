import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardBody, Form, FormGroup, Input, Label, Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import man from "../assets/images/dashboard/user.png";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { API_USER } from "../redux/apiRoutes";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginUser } from "../redux/user/action";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const dispatch = useDispatch();

  const loginAuth = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (email === "") {
      toast.error('Ingresa tu usuario', {style:{color:'white'}});
      setLoading(false);
      return;
    }

    if (password === "") {
      toast.error('Ingresa tu contraseña', {style:{color:'white'}});
      setLoading(false);
      return;
    }
  
    await axios.get(`${API_USER}/login?user=${email}&password=${password}`).then(response => {
      if(response.data.success){
        dispatch(loginUser(response.data.user));
    
        history(`${process.env.PUBLIC_URL}/inicio`);
      }else{
        toast.error("Combinación Usuario y Contraseña incorrectos", {style:{color:'white'}});
      }
      setLoading(false);
    }).catch(e => {
      setLoading(false);
      toast.error("No se pudo hacer login: " + e.message, {style:{color:'white'}});
    });
  };

  const handleLogin = async (e) => {
    if (e.key === "Enter" || e === "send") {
      loginAuth(e);
    }
  }

  return (
    <div className="page-wrapper" style={{background:'#2C3791'}}>
      <Container fluid={true} className="p-0">
        {/*  <!-- login page start--> */}
        <div className="authentication-main m-0 only-login">
          <Row>
            <Col md="12">
              <div className="auth-innerright">
                <div className="authentication-box">
                  <CardBody className="d-flex h-100">
                    <div className="cont text-center b-light">
                      <img src={require("../assets/images/logo/logo-inm.png")} alt="Instituto Nelson Mandela" width={90} />
                      <div style={{display:'flex', justifyContent:'center'}}>
                        <Form className="theme-form">
                          <h5 style={{fontWeight:'bold', marginTop:20}}>MiApp Insituto Nelson Mandela</h5>
                          <br/>
                          <FormGroup>
                            <Label className="col-form-label pt-0">Usuario</Label>
                            <Input className="form-control" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Usuario" onKeyDown={handleLogin} />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Contraseña</Label>
                            <Input className="form-control" type="password" placeholder="Contraseña" name="password" value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handleLogin} />
                          </FormGroup>
                          <FormGroup className="d-flex flex-wrap mt-3 mb-0">
                            {loading ? (
                              <Button color="primary d-block w-50 mt-3" disabled={loading}>
                                INGRESANDO...
                              </Button>
                            ) : (
                              <Button color="primary d-block w-50 mt-3" onClick={(event) => loginAuth(event)}>
                                ACCEDER
                              </Button>
                            )}
                          </FormGroup>
                        </Form>
                      </div>
                    </div>
                  </CardBody>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <ToastContainer />
        {/* <!-- login page end--> */}
      </Container>
    </div>
  );
};

export default Signin;
